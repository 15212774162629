import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { IMenuList, MENUS, SUBMENUS, BACKMENUS, IMenu } from './models/menulistitemModel';
import { IUserMenus } from '../ws/models/userModels';

const useStyles = makeStyles()((theme) => ({
    selectedItemBackground: {
        backgroundColor: theme.palette.secondary.main,
    },
    selectedItem: {
        '& span': {
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
        },
    },
    notSelectedItem: {
        '& span': {
            color: theme.palette.text.disabled,
            [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                fontSize: '0.8rem',
            },
        },
    },
    version: {
        position: 'absolute',
        bottom: '10px',
        paddingLeft: '0px',
        marginLeft: '0px',
    },
}));

export const MenuList: React.FC<IMenuList> = (props) => {
    const { classes } = useStyles();
    const {
        state: { info: userInfo },
    } = useContext(AuthContext);

    return (
        <>
            {MENUS.filter((m: IMenu) => userInfo!.menus[m.userProp as keyof IUserMenus]).map((m) => {
                return (
                    <ListItem
                        key={m.label}
                        button
                        className={props.menu.label === m.label ? classes.selectedItemBackground : ''}
                        onClick={() => props.changeMenu(m)}>
                        <ListItemIcon>
                            <m.icon color={props.menu.label === m.label ? 'primary' : 'disabled'} />
                        </ListItemIcon>
                        <ListItemText
                            primary={m.label.toUpperCase()}
                            color={props.menu.label === m.label ? 'primary' : 'disabled'}
                            className={props.menu.label === m.label ? classes.selectedItem : classes.notSelectedItem}
                        />
                    </ListItem>
                );
            })}
        </>
    );
};

// export const SubMenuList: React.FC<IMenuList> = (props) => {
//     const { classes } = useStyles();
//     const {
//         state: {
//             info: { master: isMaster },
//         },
//     } = useContext(AuthContext);

//     if (!isMaster) {
//         return (
//             <>
//                 {SUBMENUS.map((menu: IMenu) => {
//                     return (
//                         <ListItem
//                             key={menu.label}
//                             button
//                             className={props.menu.label === menu.label ? classes.selectedItemBackground : ''}
//                             onClick={() => props.changeMenu(menu)}>
//                             <ListItemIcon>
//                                 <menu.icon color={props.menu.label === menu.label ? 'primary' : 'disabled'} />
//                             </ListItemIcon>
//                             <ListItemText
//                                 primary={menu.label.toUpperCase()}
//                                 color={props.menu.label === menu.label ? 'primary' : ''}
//                                 className={
//                                     props.menu.label === menu.label ? classes.selectedItem : classes.notSelectedItem
//                                 }
//                             />
//                         </ListItem>
//                     );
//                 })}
//             </>
//         );
//     } else {
//         return null;
//     }
// };

// export const BackMenuList: React.FC<IMenuList> = (props) => {
//     const { classes } = useStyles();
//     const {
//         state: {
//             info: { backoffice: isBackOffice },
//         },
//     } = useContext(AuthContext);

//     if (isBackOffice) {
//         return (
//             <>
//                 {BACKMENUS.map((menu: IMenu) => {
//                     return (
//                         <ListItem
//                             key={menu.label}
//                             button
//                             className={props.menu.label === menu.label ? classes.selectedItemBackground : ''}
//                             onClick={() => props.changeMenu(menu)}>
//                             <ListItemIcon>
//                                 <menu.icon color={props.menu.label === menu.label ? 'primary' : 'disabled'} />
//                             </ListItemIcon>
//                             <ListItemText
//                                 primary={menu.label.toUpperCase()}
//                                 color={props.menu.label === menu.label ? 'primary' : ''}
//                                 className={
//                                     props.menu.label === menu.label ? classes.selectedItem : classes.notSelectedItem
//                                 }
//                             />
//                         </ListItem>
//                     );
//                 })}
//             </>
//         );
//     } else {
//         return null;
//     }
// };

export const Version = () => {
    const { classes } = useStyles();

    return (
        <List className={classes.version}>
            <ListItem>
                <ListItemText primary="1.4.2" className={classes.notSelectedItem} />
            </ListItem>
        </List>
    );
};