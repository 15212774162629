import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';
import { ws } from '../../ws/call';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import IFollowUpBody from './followup/FollowUpBody';
import { makeStyles } from 'tss-react/mui';
import { IDayFollowUp, IFollowUp } from '../../ws/models/followUpModels';

const useStyles = makeStyles()((theme) => ({
    typography: {
        alignSelf: 'center',
        fontWeight: 'bold'
    },
}));

const FollowUps: React.FC<{}> = () => {
    const { classes } = useStyles();

    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);

    const [followUp, setFollowUp] = useState<IDayFollowUp[]>([]);

    useEffect(() => {
        setLoading(true);

        ws.getFollowUps(userInfo!.workerid, token!)
            .then((res: IDayFollowUp[]) => {
                setFollowUp(res);
            })
            .catch((err: any) => {
                setError({
                  hasError: true,
                  title: 'FollowUp Error',
                  message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return  <>
                {
                    followUp.map((fu: IDayFollowUp, idx: number) => {
                        return <Accordion key={idx}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}>
                                        <Typography className={classes.typography} variant='h6' color='secondary'>
                                            { fu.list[0].date.toLocaleDateString() }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <IFollowUpBody data={ fu.list } />
                                    </AccordionDetails>
                                </Accordion>
                    })
                }
            </>
}

export default FollowUps;