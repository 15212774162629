import { Grid, TextField } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    textField: {
        width: '100%'
    }
}));

interface IDashboardItem {
    xs: number,
    name: string,
    label: string,
    value: string | number
}

const DashboardItem: React.FC<IDashboardItem> = ({ xs, name, label, value }) => {

    const { classes } = useStyles();

    return <Grid item xs={xs}>
        <TextField
            id={name}
            className={classes.textField}
            color='secondary'
            focused
            label={label}
            value={value}
            InputProps={{
                readOnly: true,
            }}
            variant="filled" />
    </Grid>
}

export default DashboardItem;