import { createContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { ILoading } from './models/loadingModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    infoColored: {
        color: theme.palette.secondary.main
    }
}));

export const LoadingContext = createContext<{
    loading: boolean;
    setLoading: (a: boolean) => void;
}>({
    loading: false,
    setLoading: (a: boolean) => { }
});

const Loading: React.FC<ILoading> = ({ open }) => {

    const { classes } = useStyles();

    return <Backdrop open={open}>
        <CircularProgress className={classes.infoColored} />
    </Backdrop>
}

const LoadingProvider: React.FC<{ children: any }> = ({ children }) => {
    
    const [loading, setLoading] = useState<boolean>(false);

    return <LoadingContext.Provider value={{ loading, setLoading }}>
        { children }
        <Loading open={loading}/>
    </LoadingContext.Provider>
}

export default LoadingProvider;
