import { Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    text: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      fontSize: '0.9rem'
    }
}));

const Copyright: React.FC<{}> = () => {

    const { classes } = useStyles();

    return <Typography variant="body2" className={classes.text} align="center">
        {'Copyright © '}
            <Link href="https://liderkit.com/" target="_blank">
                LiderKit
            </Link>{' '}
        {new Date().getFullYear()}
        </Typography>
}

export default Copyright;