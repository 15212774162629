import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff500b',
    },
    secondary: {
      main: '#373F51',
    },
    info: {
      main: '#008dd5',
    },
    warning: {
      main: '#fff05a',
    },
    success: {
      main: '#a2faa3',
    },
    error: {
      main: '#ac80a0',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  typography: {
    fontSize: 16
  }
});

export default theme;
