import { useState } from 'react';
import { Button, FormControl, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Add as AddIcon, CheckCircle, Circle, Delete, RemoveCircleOutline } from '@mui/icons-material';
import { ws } from '../../ws/call';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';
import { makeStyles } from 'tss-react/mui';
import { IAbentMotive, IAbsent, IAbsentLine, IAggrAbsentLines, INewAbsentLine, IUnavailableDay } from '../../ws/models/absentsModels';
import AbsentTotals from './absents/AbsentTotals';
import { YesNoContext } from '../../context/YesNoProvider';
import AddAbsent from './absents/AddAbsent';
import { x3cast } from '../../ws/x3cast';
import TablePaginationActions from './absents/TablePaginationActions';

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: '2px 4px',
        margin: '0 0 10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 40px)'
    },
    select: {
        marginTop: '20px',
        marginBottom: '50px'
    },
    form: {
        minWidth: '200px',
    },
    iconBtn: {
        padding: '10px'
    },
    table: {
        width: '100%'
    },
    tablerow: {
        '&:last-child td, &:last-child th': {
            border: '0'
        }
    },
    firsttitle: {
        width: '5px'
    },
    title: {
        fontWeight: 'bold'
    },
    filterButton: {
        color: theme.palette.text.primary,
		backgroundColor: theme.palette.primary.main,
        marginTop: '25px',
		marginBottom: '10px',
    },
    tablePagination: {
        width: '100%'
    }
}));

const getInitialYear = () => new Date().getFullYear();
const years = (): number[] => {
    const curYear = getInitialYear();
    const result: number[] = [];
    for(let y = curYear; y >= curYear - 2; y--) {
        if(y >= 2023) {
            result.unshift(y);
        }
    }
    return result;
}

const Absents: React.FC<{}> = () => {

    const { classes } = useStyles();

    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);
    const { setYesNo } = useContext(YesNoContext);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [unavailableDays, setUnavailableDays] = useState<IUnavailableDay[] | undefined>(undefined);
    const [absentMotives, setAbsentMotives] = useState<IAbentMotive[] | undefined>(undefined);
    const [curYear, setCurYear] = useState<number>(getInitialYear());
    const [absent, setAbsent] = useState<IAbsent | undefined>(undefined);
    // const [aggrAbsentLines, setAggrAbsentLines] = useState<IAggrAbsentLines | undefined>(undefined);

    useEffect(() => {
        // callUnavailableDates();
        callMotives();
    }, [])

    useEffect(() => {
        callAbsent(curYear);
    }, [curYear]);

    const callAbsent = (year: number) => {
        setLoading(true);
        setPage(0);

        ws.getAbsent(year, userInfo!.customerId, token!)
        .then((a: IAbsent) => {
            setAbsent(a);
        })
        .catch((err: any) => {
            setError({
              hasError: true,
              title: 'Absents Error - Call Absent',
              message: err.message
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    // const updateAbsent = (a: IAbsent) => {
    //     // setAggrAbsentLines(aggreagateAbsentLines(a.absentLines));
    //     setAbsent(a);
    // }

    // const callUnavailableDates = () => {
    //     setLoading(true);

    //     ws.getUnavailableDates(userInfo!.holydaysAvailabilityId, token!)
    //     .then((ud: IUnavailableDay[]) => {
    //         setUnavailableDays(ud);
    //     })
    //     .catch((err: any) => {
    //         setError({
    //           hasError: true,
    //           title: 'Absents Error - Unavailable Dates',
    //           message: err.message
    //         });
    //     })
    //     .finally(() => {
    //         setLoading(false);
    //     })
    // }

    const callMotives = () => {
        setLoading(true);

        ws.getAbsentMotives(token!)
        .then((am: IAbentMotive[]) => {
            setAbsentMotives(am);
        })
        .catch((err: any) => {
            setError({
              hasError: true,
              title: 'Get Motives Error',
              message: err.message
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleAdd = () => {
        if(absentMotives) {// && unavailableDays) {
            setOpenAdd(true);
        } else {
            setError({
                hasError: true,
                title: 'Add Absent Lines Error',
                message: 'Must have Absent Motives and Unavailable Days from X3!'
              });
        }
    }

    const addLine = (newAbsentLines: INewAbsentLine[]) => {
        setLoading(true);

        let request: INewAbsentLine[] = [];

        // linhas existentes
        request = absent!.absentLines.map((l: IAbsentLine): INewAbsentLine => ({
            date: x3cast.dateToX3Date(l.date),
            motive: l.motive,
            duration: l.duration,
            type: l.type
        }));

        // nova linha
        newAbsentLines.forEach((nl: INewAbsentLine) => {
            request.push({
                date: nl.date,
                motive: nl.motive,
                duration: nl.duration,
                type: nl.type
            })
        })

        ws.addAbsentLine(absent!.id, request, token!)
        .then((absent: IAbsent) => {
            console.log('absent', absent)
            setAbsent(absent);
            setOpenAdd(false);
        })
        .catch((err: any) => {
            setError({
              hasError: true,
              title: 'Add Absent Lines Error',
              message: err.message
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const tryDeleteLine = (al: IAbsentLine) => {
        if(al.apprHR || al.apprResponsable) {
            setError({
                hasError: true,
                title: 'Absents Error - Try Delete Line',
                message: 'Absent already approved!'
            })
        } else {
            setYesNo({
                hasYesNo: true,
                title: 'Atención!',
                content: `Borrar ${al.typeStr} (${al.duration} horas) de ${al.dateStr}?`,
                handleNo: () => {},
                handleYes: () => deleteLine(al)
            });
        }
    }

    const deleteLine = (al: IAbsentLine) => {
        setLoading(true);

        ws.deleteAbsentLines(absent!.id, [al.id], token!)
        .then((absent: IAbsent) => {
            setAbsent(absent);
        })
        .catch((err: any) => {
            setError({
              hasError: true,
              title: 'Delete Absent Lines Error',
              message: err.message
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - absent!.absentLines!.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
      ) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(absent) 
    {
        return <>
            <FormControl className={classes.form}>
                <Select
                    value={curYear}
                    label='Ano'
                    className={classes.select}
                    onChange={(ev: any) => setCurYear(ev.target.value!)}>
                    {
                        years().map((y: number, idx: number) => {
                            return <MenuItem key={idx} value={y}>N{y === getInitialYear() ? '' : `-${getInitialYear() - y}`}: {y}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <AbsentTotals absent={absent} />
            <TableContainer component={Paper}>
                <Table className={classes.table} size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' className={classes.title}>Fecha</TableCell>
                            <TableCell align='center' className={classes.title}>Tipo Asunto</TableCell>
                            <TableCell align='center' className={classes.title}>Motivo</TableCell>
                            <TableCell align='center' className={classes.title}>Duración (Hrs)</TableCell>
                            <TableCell align='center' className={classes.title}>Responsable</TableCell>
                            <TableCell align='center' className={classes.title}>HR</TableCell>
                            <TableCell align='left' className={classes.firsttitle}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0 && absent?.absentLines
                            ? absent?.absentLines.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : absent?.absentLines
                        ).map((al: IAbsentLine, idx: number) => (
                            <TableRow key={idx}>
                                <TableCell align='center'>{al.dateStr}</TableCell>
                                <TableCell align='center'>{al.typeStr}</TableCell>
                                <TableCell align='center'>{al.motiveStr}</TableCell>
                                <TableCell align='center'>{al.duration}</TableCell>
                                <TableCell align='center'>
                                    { al.apprResponsable ? <CheckCircle color='success'/> : <RemoveCircleOutline color='error' /> }
                                </TableCell>
                                <TableCell align='center'>
                                    { al.apprHR ? <CheckCircle color='success'/> : <RemoveCircleOutline color='error' /> }    
                                </TableCell>
                                <TableCell align='left' className={classes.firsttitle}>
                                    <IconButton onClick={() => tryDeleteLine(al)}>
                                        <Delete color='error' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                className={classes.tablePagination}
                                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={absent.absentLines.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions} />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Button
                className={classes.filterButton}
                variant='contained'
                onClick={handleAdd}
                startIcon={<AddIcon />}>
                Añadir
            </Button>
            <AddAbsent 
                absentLines={absent.absentLines!}
                unavailableDays={unavailableDays!}
                absentMotives={absentMotives!}
                open={openAdd}
                setOpenAdd={setOpenAdd}
                add={addLine}/>
        </>
    } else {
        return null;
    }
}

export default Absents;