import { Box, IconButton } from "@mui/material";
import theme from "../../../theme";
import { 
    KeyboardArrowRight, 
    LastPage as LastPageIcon,
    KeyboardArrowLeft,
    FirstPage as FirstPageIcon  } from "@mui/icons-material";

interface TablePaginationActionsProps {
count: number;
page: number;
rowsPerPage: number;
onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
) => void;
}

export default function TablePaginationActions(props: TablePaginationActionsProps) {

const { count, page, rowsPerPage, onPageChange } = props;

const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
) => {
    onPageChange(event, 0);
};

const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
};

const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
};

const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
};

return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
    <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
    >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
    </IconButton>
    <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
    >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
    </IconButton>
    <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
    >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
    <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
    >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
    </IconButton>
    </Box>
);
}