import axios, { AxiosResponse } from 'axios';
import { x3cast } from './x3cast';
import hash from 'hash.js';
import { faker } from '@faker-js/faker/locale/es';
import { IUser, IUserData } from './models/userModels';
import { ICustomer } from './models/customerModels';
import { ExtFile, UploadConfig } from '@files-ui/react';
import { IDayCheck } from './models/inoutcheckModels';
import { IDayFollowUp, IFollowUp } from './models/followUpModels';
import { getDateString } from '../helpers/date';
import { ABSENTTYPES, IAbentMotive, IAbsent, IAbsentLine, INewAbsentLine, IUnavailableDay, TAbsentType } from './models/absentsModels';
import { IEmpleado } from './models/empleadoModels';

const salt = 'xZJxE8Hm2jQD883nYrH8M8wpWGm62q';

const XJAEMPMKO = 'XJAEMPMKO';
const XJAYVAC = 'XJAYVAC';
const XJAYHOLY = 'XJAYHOLY';
const XJAADIDET = 'XJAADIDET';

const _api = '/api'; //http://localhost:3333/api';

const randomKey = (): string => {
    let possibleChars = 'abcdefghijklmnopqrstuvyxwzABCDEFGHIJKLMNOPQRSTUVYXWZ1234567890';
    let clientKey = '';

    for (let i = 0; i < 10; i++) {
        clientKey += possibleChars[Math.floor(Math.random() * possibleChars.length)];
    }

    return clientKey;
};

const toUser = (u: any, e: any): IUserData => {
    return {
        id: u.YCAU0_1.YUID,
        name: u.YCAU0_1.YLOGIN,
        customerId: u.YCAU0_2.YCUSTOMER,
        customer: u.YCAU0_2.ZYCUSTOMER || u.YCAU0_2.YCUSTOMER,
        NIF: u.YCAU1_1.XCRN,
        customerData: undefined,
        empleadoData: toEmpleado(e),
        workerid: u.YCAU0_2.EMPNUM,
        holydaysAvailabilityId: u.YCAU0_2.UVYCOD,
        email: u.YCAU0_2.YEMAIL,
        avatar: u.YCAU0_1.YAVATAR,
        validated: u.YCAU0_1.YVALID === '2',
        lastLogin: x3cast.x3dateToString(u.YCAU0_2.YLASTLOGIN),
        photo: {
            img: u.YCAU2_1?.IMG,
            mimeType: u.YCAU2_1?.IMG_MIME
        },
        menus: {
            dashboard: true,
            filerepo: true,
            folders: true,
            inoutcheck: u.YCAU1_1.YPBIOMETRICO === '2',
            workloads: true,
            followups: true,
            salaryreceipt: true,
            absents: true
        }
    };
};

const toInOutCheck = (u: any[]): IDayCheck[] => {

    const result: IDayCheck[] = [];

    const aggregated = u.reduce((acc, cur) => {
        (acc[cur.Fecha] = acc[cur.Fecha] || []).push({
            date: cur.Fecha,
            time: `${cur.Hora.substring(0,2)}:${cur.Hora.substring(2,4)}:${cur.Hora.substring(4)}`,
            terminalId: cur.IdTerminal,
            state: cur.Estado,
            nif: cur.NIF,
            name: cur.Nombre,
            secondName: cur.Apellidos
        });
        return acc;
    }, {});

    Object.entries(aggregated)
        .sort((day1: [string, unknown], day2: [string, unknown]) => Number(day2[0]) - Number(day1[0]))
        .forEach((day: [string, unknown]) => { result.push({ date: day[0], list: aggregated[day[0]] }) })

    return result;
};

const toInOutCheck2 = (u: any[]): IDayCheck[] => {

    const result: IDayCheck[] = [];

    const aggregated = u.reduce((acc, cur) => {
        (acc[cur.YDATA] = acc[cur.YDATA] || []).push({
            date: cur.YDATA,
            time: cur.YHORA.substring(11,19),
            terminalId: cur.YIDTERMINAL,
            state: cur.YESTADO,
            nif: cur.YNIF,
            idEmpleado: cur.YIDEMPLEADO,
            name: cur.YNOME,
            secondName: cur.YAPELIDO
        });
        return acc;
    }, {});

    Object.entries(aggregated)
        .sort((day1: [string, unknown], day2: [string, unknown]) => Number(day2[0]) - Number(day1[0]))
        .forEach((day: [string, unknown]) => { result.push({ date: day[0], list: aggregated[day[0]] }) })

    return result;
};

const toEmpleado = (e: any): IEmpleado => {
    return {
        id: e.EMP0_1.CODEMPLEAD,
        name: e.EMP0_1.NOMBRE,
        photo: e.EMP0_1.XFOTO,
        photoMime: e.EMP0_1.XFOTO_MIME,
        personalData: { 
            nif: e.YEP_1.XCRN,
            centro: e.YEP_1.XCENTRO,
            codigo: e.YEP_1.XCOD,
            firstName: e.YEP_1.XNOMBRE,
            lastName: e.YEP_1.XAPELLIDO,
            birth: e.YEP_1.XNACIMIENTO,
        },
        contacts: {
            address: e.YEP_2.XDIRECCION,
            city: e.YEP_2.XPOBLACION,
            state: e.YEP_2.XPROVINCIA,
            postalCode: e.YEP_2.XPOSTAL,
            phone: e.YEP_2.XTELEFONO
        },
        department: {
            name: e.YEP_3.XDEPART,
            supervisor: e.YEP_3.XSUPERVISOR,
            shift: e.YEP_3.XTURNO,
            shiftDesc: e.YEP_3.XTURNO_LBL
        }
    }
};

const toCustomer = (u: any): ICustomer => {

    const beginDate: Date = x3cast.x3dateToOnlyDate(u.BPR0_1.XPFECHAALTA);

    return {
        id: u.BPR0_1.BPRNUM,
        name: u.BPR0_1.BPRNAM1,
        shortName: u.BPR1_1.BPRLOG,
        documentNr: u.BPR1_1.CRN,
        documentType: u.BPR1_1.DOCTYP_LBL,
        beginDate: dateString(beginDate),
        age: diffDates(beginDate, new Date()),
        country: u.BPR1_1.ZCRY,
        language: u.BPR1_1.ZLAN,
        currency: u.BPR1_1.CUR,
        banks: u.BIDB_1.map((b: any, idx: number) => ({
            id: Number(b.BIDBPAADD),
            country: b.BIDCRY,
            iban: b.IBAN,
            accountNr: b.BIDNUM,
            default: b.BIDNUMFLG === '2',
            currency: b.BIDCUR,
            residence: b.ACCNONREI === '2',
            base: b.PAB1,
            name: b.BNF,
            base2: b.PAB2,
            base3: b.PAB3,
            base4: b.PAB4,
            bic: b.BICCOD,
            middleBank: b.MIDPAB1,
            middleBase2: b.MIDPAB2,
            middleBase3: b.MIDPAB3,
            middleBase4: b.MIDPAB4,
            middleBIC: b.BCIPS,
            middleCountry: b.MIDCRY
        })),
        addresses: u.BPAB_1.map((a: any, idx: number) => ({
            id: idx + 1,
            region: a.SAT,
            city: a.CTY,
            country: a.CRYNAM,
            postalCode: a.POSCOD,
            default: a.BPAADDFLG === '2',
            address1: a.ADDLIG1,
            address2: a.ADDLIG2,
            address3: a.ADDLIG3,
        })),
        contacts: u.BPAB_1.map((c: any, idx: number) => ({
            id: idx + 1,
            phone1: c.TEL1,
            phone2: c.TEL2,
            phone3: c.TEL3,
            phone4: c.TEL4,
            phone5: c.TEL5,
            email1: c.WEB1,
            email2: c.WEB2,
            email3: c.WEB3,
            email4: c.WEB4,
            email5: c.WEB5,
        })),
    }
};

const toFollowUp = (u: any): IDayFollowUp[] => {

    const result: IDayFollowUp[] = [];
    const empnum: string = u.GRP1.ZEMPNUM;

    const aggregated = u.GRP2.reduce((acc: any, cur: any) => {

        const item: IFollowUp = {
            date: x3cast.x3dateToJSDate(cur.ZMTKDAT),
            MTKDAT: cur.ZMTKDAT,
            OPENUM: cur.ZOPENUM,
            STDOPENUM: cur.ZSTDOPENUM,
            XOPETIM: cur.ZXOPETIM,
            XDESCRIPOPER: cur.ZXDESCRIPOPER,
            EMPNUM: empnum,
            MFGNUM: cur.ZMFGNUM,
            MFGTRKNUM: cur.ZMTKNUM,
            CPLWST: cur.ZCPLWST,
            CPLQTY: cur.ZCPLQTY
        };

        (acc[item.MTKDAT] = acc[item.MTKDAT] || []).push(item);
        return acc;
    }, { });

    Object.entries(aggregated)
            .sort((day1: [string, unknown], day2: [string, unknown]) => Number(day2[0]) - Number(day1[0]))
            .forEach((day: [string, unknown]) => { result.push({ date: day[0], list: aggregated[day[0]] }) })

    return result;
};

const toAbsent = (u: any): IAbsent => {
    return {
        id: u.YVAC0_1.NUM,
        year: Number(u.YVAC0_1.YEA),
        totalAbsent: Number(u.YVAC0_1.YABSTIMTOT),
        totalLicense: Number(u.YVAC0_1.YLICTIMTOT),
        vacationsLastYear: Number(u.YVAC0_2.YVACTIMN1),
        vacationsCurYear: Number(u.YVAC0_2.YVACTIMN),
        vacationsTotal: Number(u.YVAC0_2.YVACTIMTOT),
        vacationsAvailable: Number(u.YVAC0_2.YVACTIMAVA),
        absentLines: u.YVAC1_1.map((a: any) => ({
            id: a.LIN.slice(0, -3),
            date: x3cast.x3dateToJSDate(a.YVACDAT),
            dateStr: x3cast.x3dateToString(a.YVACDAT),
            type: Number(a.YVACTYP),
            typeStr: ABSENTTYPES[Number(a.YVACTYP) - 1],
            motive: a.YMOTIVO,
            motiveStr: a.ZYMOTIVO,
            duration: Number(a.YVACTIM),
            apprResponsable: a.YVACUSR1 === '2',
            apprHR: a.YVACUSR2 === '2'
        }))
    }
}

const toUnavailableDays = (u: any): IUnavailableDay[] => {
    return u.GRP2.map((ud: any) => ({
        day: x3cast.x3dateToJSDate(ud.YDAT),
        description: ud.YDES
    }));
}

const toAbsentMotives = (u: any): IAbentMotive[] => {
    const result: IAbentMotive[] = [];

    u.GRP2.forEach((lin: any) => {
        if(lin.XACTIVO === '2') {
            result.push({
                code: lin.XCODE,
                description: lin.XDES,
                group: lin.XGRUPO
            })
        }
    });

    return result;
}

const dateString = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const diffDates = (date1: Date, date2: Date): string => {
    var diffDate = new Date(date2.valueOf() - date1.valueOf());
    return ((diffDate.getFullYear() - 1970) + ' años ' +
        diffDate.getMonth() + ' meses y ' + (diffDate.getDate()-1) + ' días');
}

/**************  FAKE DATA  ****************/

const fakeUser = (): IUserData => {
    let rnd = faker.number.int(100);

    return {
        avatar: `D${rnd}`,
        password: '',
        validated: true,
        customer: faker.person.firstName(),
        customerId: rnd.toString(),
        NIF: faker.number.bigInt().toString(),
        customerData: toCustomer(fakeCustomer()),
        empleadoData: toEmpleado(fakeEmpleado()),
        workerid: `EMP${rnd}`,
        holydaysAvailabilityId: `AA${rnd}`,
        email: `demo${rnd}@f5it.pt`,
        id: `CAUDEMO${rnd}`,
        lastLogin: faker.date.past().toString(),
        name: `Demo${rnd}`,
        photo: {
            img: '',
            mimeType: 'image/png'
        },
        menus: {
            dashboard: true,
            filerepo: true,
            folders: true,
            inoutcheck: true,
            workloads: true,
            followups: true,
            salaryreceipt: true,
            absents: true
        }
    };
};

const fakeEmpleado = (): object => {
    return {
        EMP0_1: {
          CODEMPLEAD: '53591433F',
          NOMBRE: 'JOSE MANUEL GARROTE GOMEZ',
          XFOTO: '',
          XFOTO_MIME: 'image/jpeg'
        },
        YEP_1: {
          XCRN: '53591433F',
          XCENTRO: 'LIDERKIT',
          XCOD: '000000001',
          XNOMBRE: 'JOSE MANUEL',
          XAPELLIDO: 'GARROTE GOMEZ',
          XNACIMIENTO: '19860426'
        },
        YEP_2: {
          XDIRECCION: 'C/ RAFAEL ROMERO 8, 3A',
          XPOBLACION: 'Andújar',
          XPROVINCIA: 'Jaén',
          XPOSTAL: '23740',
          XTELEFONO: '646348131'
        },
        YEP_3: {
          XDEPART: '00037 - INFORMATICA',
          XSUPERVISOR: 'MANUEL SORIA GUERRERO',
          XTURNO: '1',
          XTURNO_LBL: 'Mañana'
        },
        YEP_4: {
          XFOTO: '',
          XFOTO_MIME: 'image/jpeg'
        },
        YCN_1: [],
        VAC_1: {
          NVACACIONE: '',
          NDIA: '',
          FECHAINI: null,
          FECHAFIN: null,
          PENDIENTE: ''
        },
        VAC_2: [],
        EMF_1: [],
        EPO_1: [],
        ADXTEC: { WW_MODSTAMP: '20240911065007', WW_MODUSER: 'WBS' }
    }
}

const fakeCustomer = (): object => {
    return {
        BPR0_1: {
          BRGCOD: '',
          BRGDES: '',
          BPRNUM: '149',
          BPRNAM1: 'GARROTE GOMEZ JOSE MANUEL'
        },
        BPR1_1: {
          BPRSHO: '',
          BPRLOG: '',
          BPRNAM: [ 'GARROTE GOMEZ JOSE MANUEL', '' ],
          LEGETT: '2',
          LEGETT_LBL: 'Sí',
          BPRFBDMAG: '1',
          BPRFBDMAG_LBL: 'No',
          CRY: 'ES',
          ZCRY: 'España',
          LAN: 'SPA',
          ZLAN: 'Español',
          CUR: 'EUR',
          ZCUR: 'EURO',
          DOCTYP: '1',
          DOCTYP_LBL: 'NIF',
          EECNUM: '',
          EVCVAL: '102',
          EVCVALDON: '1',
          EVCVALDON_LBL: 'No'
        },
        BPR1_2: { BETFCY: '1', BETFCY_LBL: 'No', FCY: '', ZFCY: '', CRN: '53591433F', },
        BPR1_3: {
          BPCFLG: '1',
          BPCFLG_LBL: 'No',
          REPFLG: '1',
          REPFLG_LBL: 'No',
          PPTFLG: '1',
          PPTFLG_LBL: 'No',
          BPPFLG: '1',
          BPPFLG_LBL: 'No',
          BPSFLG: '1',
          BPSFLG_LBL: 'No',
          BPRACC: '2',
          BPRACC_LBL: 'Sí',
          PRVFLG: '1',
          PRVFLG_LBL: 'No',
          BPTFLG: '1',
          BPTFLG_LBL: 'No',
          DOOFLG: '1',
          DOOFLG_LBL: 'No',
          CCNFLG: '1',
          CCNFLG_LBL: 'No'
        },
        BPAB_5: { NUMBPA: '1', IDENT: '1149', TRTBPAB: '' },
        BPAB_1: [
          {
            CODADR: '1',
            BPADES: '',
            BPACRY: 'ES',
            CRYNAM: 'España',
            ADDLIG1: '',
            ADDLIG2: '',
            ADDLIG3: '',
            POSCOD: '',
            CTY: 'ANDUJAR',
            SAT: 'JAEN',
            TEL1: '646348131',
            TEL2: '',
            TEL3: '',
            TEL4: '',
            TEL5: '',
            WEB1: 'informatica@liderkit.com',
            WEB2: '',
            WEB3: '',
            WEB4: '',
            WEB5: '',
            FCYWEB: '',
            EXTNUM: '',
            BPAADDFLG: '2',
            BPAADDFLG_LBL: 'Sí',
            FLMOD: '0',
            FLMODFONC: '0'
          }
        ],
        BPR2_1: {
          ACCCOD: 'STD',
          ZACCCOD: 'Tiers divers',
          GRUGPY: '',
          ZGRUGPY: '',
          GRUCOD: '',
          ACS: '',
          ZACS: '',
          BPRGTETYP: '',
          ZBPRGTETYP: '',
          CFOEXD: '1',
          CFOEXD_LBL: 'No',
          MODPAM: '',
          ZMODPAM: '',
          CSLBPR: ''
        },
        BIDB_1: [
            {
                BIDBPAADD: '1',
                BIDCRY: 'ES',
                IBAN: 'ES53',
                BIDNUM: '01280321170100005401',
                BIDNUMFLG: '2',
                BIDNUMFLG_LBL: 'Sí',
                BIDCUR: 'EUR',
                ACCNONREI: '1',
                ACCNONREI_LBL: 'No',
                PAB1: '123',
                BNF: 'JOSE',
                PAB2: '',
                PAB3: '',
                PAB4: '',
                BICCOD: 'BPCIC',
                MIDPAB1: 'MONTEPIO',
                MIDPAB2: 'asdasd',
                MIDPAB3: 'asdasd',
                MIDPAB4: '',
                MIDBICCOD: 'BCIPS',
                MIDCRY: 'FJ'
              }
        ],
        CNTB_3: { TRTBPAB: '', IDENT: '1149', NUMBPA: '1' },
        CNTB_1: [],
        BPE_1: [],
        ADXTEC: { WW_MODSTAMP: '20231024143328', WW_MODUSER: 'JMGG' }
    }
}

const fakeFiles = (): ExtFile[] => {
    return [{
        id: faker.number.int(10000),
        size: faker.number.int(100) * 1024 * 1024,
        type: faker.system.mimeType(),
        name: `${faker.system.fileName()}-${faker.system.fileName()}-${faker.system.fileName()}`,
        downloadUrl: faker.system.fileName(),
        extraData: {
            ctime: new Date().getTime(),
            mtime: new Date().getTime()
        }
    },
    {
        id: faker.number.int(10000),
        size: faker.number.int(100) * 1024 * 1024,
        type: faker.system.mimeType(),
        name: `${faker.system.fileName()}-${faker.system.fileName()}-${faker.system.fileName()}`,
        downloadUrl: faker.system.fileName(),
        extraData: {
            ctime: new Date().getTime(),
            mtime: new Date().getTime()
        }
    },
    {
        id: faker.number.int(10000),
        size: faker.number.int(100) * 1024 * 1024,
        type: faker.system.mimeType(),
        name: `${faker.system.fileName()}-${faker.system.fileName()}-${faker.system.fileName()}`,
        downloadUrl: faker.system.fileName(),
        extraData: {
            ctime: new Date().getTime(),
            mtime: new Date().getTime()
        }
    }]
}

const fakeInOutCheck = (): any[] => {

    const arr = [];
    let f = 0;
    for(let i = 0; i < 3; i++) {

        let date = faker.date.recent({ days: 10 });
        
        for(let j = 0; j < 4; j++) {
            arr.push({
                Fecha: getDateString(date),
                Hora: faker.date.anytime().toLocaleTimeString().replace('/', ''),
                IdTerminal: 'T1',
                Estado: 'Open',
                NIF: `${faker.number.int({ min: 100000000, max: 999999999 })}`,
                Nombre: faker.person.firstName(),
                Apellidos: `${faker.person.lastName()} ${faker.person.lastName()}`
            })
        }
    }
    
    return arr;
}

const fakeInOutCheck2 = (): any[] => {
    return [
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240826',
          YHORA: '2024-08-26T06:58:44Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240826',
          YHORA: '2024-08-26T15:04:34Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240827',
          YHORA: '2024-08-27T06:58:27Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240827',
          YHORA: '2024-08-27T15:05:33Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240828',
          YHORA: '2024-08-28T07:01:49Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240828',
          YHORA: '2024-08-28T15:01:27Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240829',
          YHORA: '2024-08-29T07:00:52Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240829',
          YHORA: '2024-08-29T15:04:52Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240830',
          YHORA: '2024-08-30T06:56:30Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240830',
          YHORA: '2024-08-30T12:29:24Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240830',
          YHORA: '2024-08-30T15:04:59Z',
          YESTADO: '3',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240902',
          YHORA: '2024-09-02T07:01:50Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240902',
          YHORA: '2024-09-02T15:06:23Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240903',
          YHORA: '2024-09-03T07:00:15Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240903',
          YHORA: '2024-09-03T15:03:55Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240904',
          YHORA: '2024-09-04T07:01:52Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240904',
          YHORA: '2024-09-04T15:11:07Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240905',
          YHORA: '2024-09-05T07:00:48Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240905',
          YHORA: '2024-09-05T13:45:07Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240905',
          YHORA: '2024-09-05T15:08:50Z',
          YESTADO: '3',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240906',
          YHORA: '2024-09-06T06:58:57Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240906',
          YHORA: '2024-09-06T15:07:34Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240909',
          YHORA: '2024-09-09T06:59:09Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240909',
          YHORA: '2024-09-09T15:02:44Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240910',
          YHORA: '2024-09-10T06:58:34Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240910',
          YHORA: '2024-09-10T15:03:37Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240911',
          YHORA: '2024-09-11T06:57:06Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240911',
          YHORA: '2024-09-11T15:09:54Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240912',
          YHORA: '2024-09-12T06:56:26Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240912',
          YHORA: '2024-09-12T15:06:59Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240913',
          YHORA: '2024-09-13T15:10:27Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240916',
          YHORA: '2024-09-16T07:00:06Z',
          YESTADO: '1',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '53591433F',
          YNOME: 'JOSE MANUEL',
          YAPELIDO: 'GARROTE GOMEZ',
          YDATA: '20240916',
          YHORA: '2024-09-16T15:08:29Z',
          YESTADO: '2',
          YIDEMPLEADO: '1',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240826',
          YHORA: '2024-08-26T06:53:18Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240826',
          YHORA: '2024-08-26T15:04:42Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240827',
          YHORA: '2024-08-27T06:49:46Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240827',
          YHORA: '2024-08-27T15:07:11Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240828',
          YHORA: '2024-08-28T06:42:54Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240828',
          YHORA: '2024-08-28T15:08:19Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240829',
          YHORA: '2024-08-29T06:50:08Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240829',
          YHORA: '2024-08-29T15:02:51Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240830',
          YHORA: '2024-08-30T06:57:01Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240830',
          YHORA: '2024-08-30T15:04:23Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240902',
          YHORA: '2024-09-02T06:53:59Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240902',
          YHORA: '2024-09-02T15:06:21Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240903',
          YHORA: '2024-09-03T06:32:40Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240903',
          YHORA: '2024-09-03T15:04:54Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240904',
          YHORA: '2024-09-04T06:53:34Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240904',
          YHORA: '2024-09-04T15:11:21Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240905',
          YHORA: '2024-09-05T06:38:20Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240905',
          YHORA: '2024-09-05T15:08:36Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240906',
          YHORA: '2024-09-06T06:48:13Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240906',
          YHORA: '2024-09-06T15:04:34Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240909',
          YHORA: '2024-09-09T06:52:26Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240909',
          YHORA: '2024-09-09T15:06:32Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240910',
          YHORA: '2024-09-10T06:51:03Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240910',
          YHORA: '2024-09-10T15:03:41Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240911',
          YHORA: '2024-09-11T06:57:31Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240911',
          YHORA: '2024-09-11T15:09:58Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240912',
          YHORA: '2024-09-12T06:59:03Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240912',
          YHORA: '2024-09-12T15:04:16Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240913',
          YHORA: '2024-09-13T06:58:21Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240913',
          YHORA: '2024-09-13T14:04:31Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '007'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240913',
          YHORA: '2024-09-13T14:05:50Z',
          YESTADO: '3',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '007'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240913',
          YHORA: '2024-09-13T15:03:27Z',
          YESTADO: '4',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240916',
          YHORA: '2024-09-16T07:00:38Z',
          YESTADO: '1',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240916',
          YHORA: '2024-09-16T08:33:42Z',
          YESTADO: '2',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '007'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240916',
          YHORA: '2024-09-16T08:37:23Z',
          YESTADO: '3',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '007'
        },
        {
          YNIF: '77360027X',
          YNOME: 'ENRIQUE',
          YAPELIDO: 'DE LA CHICA SALIDO',
          YDATA: '20240916',
          YHORA: '2024-09-16T15:19:21Z',
          YESTADO: '4',
          YIDEMPLEADO: '2',
          YIDTERMINAL: '008'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240826',
          YHORA: '2024-08-26T06:59:02Z',
          YESTADO: '1',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '003'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240826',
          YHORA: '2024-08-26T15:01:54Z',
          YESTADO: '2',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '002'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240827',
          YHORA: '2024-08-27T06:28:14Z',
          YESTADO: '1',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '003'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240827',
          YHORA: '2024-08-27T15:02:18Z',
          YESTADO: '2',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '002'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240828',
          YHORA: '2024-08-28T06:29:36Z',
          YESTADO: '1',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '003'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240828',
          YHORA: '2024-08-28T15:02:36Z',
          YESTADO: '2',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '003'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240829',
          YHORA: '2024-08-29T06:31:45Z',
          YESTADO: '1',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '003'
        },
        {
          YNIF: '26037463Z',
          YNOME: 'CRISTOBAL',
          YAPELIDO: 'ARAQUE PARRA',
          YDATA: '20240830',
          YHORA: '2024-08-30T06:38:32Z',
          YESTADO: '1',
          YIDEMPLEADO: '4',
          YIDTERMINAL: '002'
        }
    ];
}

const fakeFollowUps = (): object => {
    return {
        GRP1: {
            ZEMPNUM: '8357'
        },
        GRP2: [
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230614",
                ZMTKNUM: "FM-LDK230618700",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS kgh mjh vjmhgv jh jmhg jhg jmhg jgjhg jhg jhgnbjg jhvbjhg ",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230614",
                ZMTKNUM: "FM-LDK230618700",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS kgh mjh vjmhgv jh jmhg jhg jmhg jgjhg jhg jhgnbjg jhvbjhg ",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230614",
                ZMTKNUM: "FM-LDK230618700",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS kgh mjh vjmhgv jh jmhg jhg jmhg jgjhg jhg jhgnbjg jhvbjhg ",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230616",
                ZMTKNUM: "FM-LDK230618702",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230615",
                ZMTKNUM: "FM-LDK230618701",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230613",
                ZMTKNUM: "FM-LDK230618699",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230609",
                ZMTKNUM: "FM-LDK230617896",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230608",
                ZMTKNUM: "FM-LDK230617895",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230612",
                ZMTKNUM: "FM-LDK230617897",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230607",
                ZMTKNUM: "FM-LDK230617894",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230531",
                ZMTKNUM: "FM-LDK230517141",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230601",
                ZMTKNUM: "FM-LDK230617142",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230602",
                ZMTKNUM: "FM-LDK230617143",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230605",
                ZMTKNUM: "FM-LDK230617144",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            },
            {
                ZCPLLAB: "FIN",
                ZCPLQTY: "1",
                ZCPLWST: "MOBRA",
                ZMFGNUM: "MNT-LDK23-0687",
                ZMTKDAT: "20230606",
                ZMTKNUM: "FM-LDK230617145",
                ZOPENUM: "0",
                ZSTDOPENUM: "46",
                ZXDESCRIPOPER: "MONTAJE DE ESTANTERIAS",
                ZXOPETIM: "7.75"
            }
        ]
    }
}

const fakeAbsent = (): object => {
    return {
        "YVAC0_1": {
            "NUM": "2023149",
            "BPRNUM": "149",
            "ZBPRNUM": "GARROTE GOMEZ JOSE MANUEL",
            "YEA": "2023",
            "W": "",
            "YABSTIMTOT": "8",
            "YLICTIMTOT": "3"
        },
        "YVAC0_2": {
            "YVACTIMN": "240",
            "W1": "        + ",
            "YVACTIMN1": "0",
            "W2": "         -",
            "YVACTIMTOT": "7",
            "W3": "        = ",
            "YVACTIMAVA": "233",
            "W4": ""
        },
        "YVAC1_1": [
            {
                "LIN": "1000",
                "YVACDAT": "20231103",
                "YVACTYP": "1",
                "YVACTYP_LBL": "Vacations",
                "YMOTIVO": "BL",
                "ZYMOTIVO": "Enfermedad Comun",
                "YVACTIM": "1",
                "YVACUSR1": "2",
                "YVACUSR1_LBL": "Sí",
                "YVACUSR2": "2",
                "YVACUSR2_LBL": "Sí"
            },
            {
                "LIN": "2000",
                "YVACDAT": "20231106",
                "YVACTYP": "1",
                "YVACTYP_LBL": "Vacations",
                "YMOTIVO": "MATR",
                "ZYMOTIVO": "Matrimonio",
                "YVACTIM": "1",
                "YVACUSR1": "2",
                "YVACUSR1_LBL": "Sí",
                "YVACUSR2": "2",
                "YVACUSR2_LBL": "Sí"
            },
            {
                "LIN": "3000",
                "YVACDAT": "20231108",
                "YVACTYP": "3",
                "YVACTYP_LBL": "License",
                "YMOTIVO": "MATR",
                "ZYMOTIVO": "Matrimonio",
                "YVACTIM": "3",
                "YVACUSR1": "2",
                "YVACUSR1_LBL": "Sí",
                "YVACUSR2": "2",
                "YVACUSR2_LBL": "Sí"
            },
            {
                "LIN": "4000",
                "YVACDAT": "20231109",
                "YVACTYP": "2",
                "YVACTYP_LBL": "Absence",
                "YMOTIVO": "BL",
                "ZYMOTIVO": "Enfermedad Comun",
                "YVACTIM": "8",
                "YVACUSR1": "2",
                "YVACUSR1_LBL": "Sí",
                "YVACUSR2": "2",
                "YVACUSR2_LBL": "Sí"
            },
            {
                "LIN": "5000",
                "YVACDAT": "20231110",
                "YVACTYP": "1",
                "YVACTYP_LBL": "Vacations",
                "YMOTIVO": "BL",
                "ZYMOTIVO": "Enfermedad Comun",
                "YVACTIM": "5",
                "YVACUSR1": "2",
                "YVACUSR1_LBL": "Sí",
                "YVACUSR2": "2",
                "YVACUSR2_LBL": "Sí"
            }
        ],
        "ADXTEC": {
            "WW_MODSTAMP": "20231112012007",
            "WW_MODUSER": "WBS"
        }
    };
}

const fakeUnavailableDays = (): object => {
    return {
        "GRP1": {
            "YCOD": "AA23"
        },
        "GRP2": [
            {
                "YDAT": "20230101",
                "YDES": "Ano Nuevo"
            },
            {
                "YDAT": "20231030",
                "YDES": "Halloween"
            },
            {
                "YDAT": "20231101",
                "YDES": "Dia de Santos"
            },
            {
                "YDAT": "20231225",
                "YDES": "Navidad"
            }
        ]
    }
}

const fakeAbsentMotives = (): object => {
    return {
        "GRP1": {
            "XTAB": "6100"
        },
        "GRP2": [
            {
                "XCODE": "BAT",
                "XDES": "Accidente Trabajo",
                "XACTIVO": "2",
                "XGRUPO": "2"
            },
            {
                "XCODE": "BL",
                "XDES": "Enfermedad Comun",
                "XACTIVO": "2",
                "XGRUPO": "2"
            },
            {
                "XCODE": "CM",
                "XDES": "Consulta Médica",
                "XACTIVO": "2",
                "XGRUPO": "2"
            },
            {
                "XCODE": "ES",
                "XDES": "Especialista",
                "XACTIVO": "2",
                "XGRUPO": "1"
            },
            {
                "XCODE": "LR",
                "XDES": "Licencia Retribuida",
                "XACTIVO": "2",
                "XGRUPO": "3"
            },
            {
                "XCODE": "MATR",
                "XDES": "Matrimonio",
                "XACTIVO": "2",
                "XGRUPO": "3"
            },
            {
                "XCODE": "VC",
                "XDES": "Vacaciones",
                "XACTIVO": "2",
                "XGRUPO": "1"
            }
        ]
    };
}

/***************************************** */

var FAKING = true;

export const ws = {
    notFaking: (): void => {
        FAKING = false;
    },

    faking: (): void => {
        FAKING = true;
    },

    testConnection: (): Promise<AxiosResponse> => {
        return axios.get(`${_api}/connect`);
    },

    /************************/
    /*****  LOGIN MENU  *****/
    /************************/
    login: (username: string, password: string): Promise<IUser> => {
        if (!FAKING) {
            const inputxml = `<PARAM><FLD NAME="YEMAIL">${username}</FLD></PARAM>`;
            return axios
                .post(`${_api}/serverkey`, { inputxml })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);

                    return res.data;
                })
                .then((jonsServerKey) => {
                    const clientKey = randomKey();
                    const encPassword = hash.sha1().update(`${salt}${password}`).digest('hex');
                    const {
                        GRP1: { YSERKEY: serverKey },
                    } = jonsServerKey;

                    const encAll = hash.sha256().update(`${salt}${encPassword}${serverKey}${clientKey}`).digest('hex');

                    const inputxml = `<PARAM>
                                        <FLD NAME="YEMAIL">${username}</FLD>
                                        <FLD NAME="YALLHASH">${encAll}</FLD>
                                        <FLD NAME="YCLIENTKEY">${clientKey}</FLD>
                                    </PARAM>`;
                    return axios.post(`${_api}/login`, { username, inputxml });
                })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                    
                    if (!res.data.user) throw new Error(`No user: User not found on X3!`);
                    if (!res.data.empleado) throw new Error(`No empleado: Empleado not found on this user!`);
                    
                    const user: string = res.data.user;
                    const empleado: string = res.data.empleado;
                    const token: string = res.data.token;

                    return { data: toUser(user, empleado), token } as IUser;
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve({ data: fakeUser(), token: 'demotoken' } as IUser);
            });
        }
    },

    /************************/
    /******    DOCS    ******/
    /************************/
    downloadDocs: (filename: string, token: string): Promise<string> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/download-global-docs/${filename}`,
                headers: { 'x-access-token': token },
                responseType: 'blob'
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                const href = URL.createObjectURL(res.data);
                return href; 
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve('asd');
            });
        }
    },

    /************************/
    /*****  FILE REPO  *****/
    /************************/
    getUserFiles: (token: string): Promise<ExtFile[]> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/get-user-files`,
                headers: { 'x-access-token': token }
            }).then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return res.data;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(fakeFiles());
            });
        }
    },

    downloadFile: (filename: string, token: string): Promise<string> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/download-file/${filename}`,
                headers: { 'x-access-token': token },
                responseType: 'blob'
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                const href = URL.createObjectURL(res.data);
                return href; 
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve('asd');
            });
        }
    },

    deleteFile: (filename: string, token: string): Promise<boolean> => {
        if(!FAKING) {
            return axios.delete(`${_api}/delete-file`, {
                headers: { 'x-access-token': token },
                data: { filename }
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return res.data;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        }
    },

    uploadFiles: (token: string): UploadConfig => {
        return {
            url: `${_api}/upload-files`,
            method: 'POST',
            headers: { 'x-access-token': token },
            cleanOnUpload: true,
         }
    },

    /************************/
    /*****    FOLDERS   *****/
    /************************/
    getUserFilesFolders: (folder: string, token: string): Promise<ExtFile[]> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/get-user-files-folders/${folder}`,
                headers: { 'x-access-token': token }
            }).then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return res.data;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(fakeFiles());
            });
        }
    },

    downloadFileFolders: (folder: string, filename: string, token: string): Promise<string> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/download-file-folders/${folder}/${filename}`,
                headers: { 'x-access-token': token },
                responseType: 'blob'
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                const href = URL.createObjectURL(res.data);
                return href; 
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve('asd');
            });
        }
    },

    /************************/
    /** IN OUT CHECK MENU  **/
    /************************/
    getInOutCheckHistoryV2: (token: string): Promise<any> => {
        if (!FAKING) {
            return axios({
               method: 'get',
               url: `${_api}/mysql/inoutcheck/v2`,
               headers: { 'x-access-token': token }
            }).then((res) => {
               if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
               return toInOutCheck2(res.data);
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toInOutCheck2(fakeInOutCheck2()));
            });
        }
    },

    getInOutCheckHistory: (userid: string, token: string): Promise<any> => {
        if (!FAKING) {
            return axios({
               method: 'get',
               url: `${_api}/mysql/inoutcheck`,
               headers: { 'x-access-token': token }
            }).then((res) => {
               if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
               return toInOutCheck(res.data);
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toInOutCheck(fakeInOutCheck()));
            });
        }
    },

    /************************/
    /*****  FOLLOW UP  *****/
    /************************/
    getFollowUps: (workerid: string, token: string): Promise<any> => {
        if (!FAKING) {
            const inputxml = `<PARAM><FLD NAME="ZEMPNUM">${workerid}</FLD></PARAM>`;
            return axios.post(`${_api}/run`, { wsname: XJAEMPMKO, inputxml, token })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                    return toFollowUp(res.data);
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toFollowUp(fakeFollowUps()))
            });
        }
    },

    /************************/
    /***  SALARY RECEIPT  ***/
    /************************/
    getSalaryReceipts: (token: string): Promise<any> => {
        if (!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/nominas`,
                headers: { 'x-access-token': token }
            }).then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return res.data;
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(fakeFiles())
            });
        }
    },

    downloadSalaryReceipt: (filename: string, token: string): Promise<string> => {
        if(!FAKING) {
            return axios({
                method: 'get',
                url: `${_api}/download-nomina/${filename}`,
                headers: { 'x-access-token': token },
                responseType: 'blob'
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                const href = URL.createObjectURL(res.data);
                return href; 
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve('asd');
            });
        }
    },

    /************************/
    /******  ABSENTS   ******/
    /************************/
    getAbsent: (year: number, customerId: string, token: string): Promise<IAbsent> => {
        if (!FAKING) {
            return axios.post(`${_api}/read`, { wsname: XJAYVAC, keys: { NUM: `${year}${customerId}` }, token })
            .then((res) => {

                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                console.log('toAbsent(res.data)', toAbsent(res.data))
                return toAbsent(res.data);
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toAbsent(fakeAbsent()))
            });
        }
    },
    
    getUnavailableDates: (
        holydaysAvailabilityId: string,
        token: string): Promise<IUnavailableDay[]> => {
        if (!FAKING) {
            const inputxml = `<PARAM>
                                <FLD NAME="YCOD">${holydaysAvailabilityId}</FLD>
                                </PARAM>`;
                                            return axios.post(`${_api}/run`, { wsname: XJAYHOLY, inputxml, token })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return toUnavailableDays(res.data);
            });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toUnavailableDays(fakeUnavailableDays()))
            });
        }
    },

    getAbsentMotives: (token: string): Promise<IAbentMotive[]> => {
        if (!FAKING) {
            const inputxml = `<PARAM><FLD NAME="XTAB">6100</FLD></PARAM>`;
            return axios.post(`${_api}/run`, { wsname: XJAADIDET, inputxml, token })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                    return toAbsentMotives(res.data);
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(toAbsentMotives(fakeAbsentMotives()))
            });
        }
    },

    deleteAbsentLines: (absentId: string, linekeys: number[], token: string): Promise<IAbsent> => {
        if (!FAKING) {
            return axios
                .post(`${_api}/deletelines`, { wsname: XJAYVAC, keys: { NUM: absentId }, blockkey: 'YVAC1~NBLIG', linekeys, token })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                    return toAbsent(res.data);
                })
        } else {
            return new Promise((resolve, reject) => {
                resolve(toAbsent(fakeAbsent()))
            });
        }
    },

    addAbsentLine: (absentId: string, lines: INewAbsentLine[], token: string) => {

        let inputxml = `<PARAM><TAB ID='YVAC1_1'>`;
        lines.forEach((l: INewAbsentLine) => {
            inputxml += `<LIN>`;
            inputxml += `<FLD NAME='YVACDAT'>${l.date}</FLD>`;
            inputxml += `<FLD NAME='YVACTYP'>${l.type}</FLD>`;
            inputxml += `<FLD NAME='YMOTIVO'>${l.motive}</FLD>`;
            inputxml += `<FLD NAME='YVACTIM'>${l.duration}</FLD>`;
            inputxml += `</LIN>`;
        });
        inputxml += `</TAB></PARAM>`;

        console.log('key', { NUM: absentId })
        console.log('xml', inputxml)

        return axios.post(`${_api}/modify`, { wsname: XJAYVAC, keys: { NUM: absentId }, inputxml, token })
                .then((res) => {
                    if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                    return toAbsent(res.data);
                })
    },

    /*************************/
    /**** CUSTOMERS MENU *****/
    /************************/
    createCustomer: (name: string, avatar: string, email: string, password: string): Promise<any> => {
        return axios
            .post(`${_api}/create-new-user`, {
                name,
                avatar,
                email,
                password: hash.sha1().update(`${salt}${password}`).digest('hex'),
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return 'ok';
            });
    },

    recoverCustomer: (email: string, pincode: string, password: string): Promise<any> => {
        return axios
            .post(`${_api}/recover-user`, {
                email,
                pin: pincode,
                password: hash.sha1().update(`${salt}${password}`).digest('hex'),
            })
            .then((res) => {
                if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
                return 'ok';
            });
    },

    sendRecoverPin: (email: string): Promise<any> => {
        return axios.post(`${_api}/recover-pin`, { email }).then((res) => {
            if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
            if (res.data.GRP1.YOK === '0') throw new Error(`Couldn't send recover pin: ${res.data.GRP1.YMESSAGE}.`);
            return 'ok';
        });
    },

    checkRecoverPin: (email: string, pinCode: number): Promise<any> => {
        return axios.post(`${_api}/check-pin`, { email, pin: pinCode }).then((res) => {
            if (res.data.error) throw new Error(`${res.data.type}: ${res.data.error}`);
            return res.data === 'ok';
        });
    },
};