import { useState } from 'react';
import { Grid, MenuItem, Select } from '@mui/material';
import { ExtFile, FileCard } from '@files-ui/react';
import { makeStyles } from 'tss-react/mui';
import { ws } from '../../ws/call';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';

const useStyles = makeStyles()((theme) => ({
    select: {
        marginTop: '20px',
        marginBottom: '50px'
    },
    filecard: {
        width: '100% !important',
        '> .files-ui-file-card-main-layer-container': {
            width: '100% !important',
        }
    }
}));

const FOLDERS: string[] = [
    'ANEXOS AL CONTRATO',
    'CONTRATOS',
    'CURRICULUM Y TITULOS',
    'DATOS PERSONALES',
    'EMBARGOS',
    'FORMACION EMPRESA',
    'JUSTIFICANTES',
    'MODELO 145',
    'PREVENCION'
];

const Folders: React.FC<{}> = () => { 
    const { classes } = useStyles();
    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);

    const [folder, setFolder] = useState<string>(FOLDERS[0]);
    const [files, setFiles] = useState<ExtFile[]>([]);

    useEffect(() => {
        getFiles(folder);
    }, []);

    const getFiles = (currentFolder: string) => {

        setLoading(true);

        ws.getUserFilesFolders(currentFolder, token!)
            .then((files: ExtFile[]) => {
                setFiles(files);
            })
            .catch((err: any) => {
                setError({
                  hasError: true,
                  title: 'File Error',
                  message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const handleChange = (ev: any) => {

        const newFolder = ev.target.value;

        setFolder(newFolder);
        getFiles(newFolder);
    };

    const handleDownload = (
        fileId: string | number | undefined,
        filename?: string | undefined
       ) => {

            if(filename) {
                setLoading(true);

                ws.downloadFileFolders(folder, filename, token!)
                    .then((href: string) => {
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href); 
                    })
                    .catch((err: any) => {
                        setError({
                        hasError: true,
                        title: 'File Error',
                        message: err.message
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            } else {
                setError({
                    hasError: true,
                    title: 'File Error',
                    message: 'No download URL'
                });
            }
    };

    return <>
            <Select
                value={folder}
                label='Carpeta'
                className={classes.select}
                onChange={handleChange}>
                {
                    FOLDERS.map((f: string, idx: number) => {
                        return <MenuItem key={idx} value={f}>{f}</MenuItem>
                    })
                }
            </Select>
            <Grid container spacing={2}>
                {
                    files.map((file: ExtFile, idx: number) => {
                        return <Grid key={idx} item xs={12} sm={12} md={12} lg={6}>
                                    <FileCard 
                                        className={classes.filecard}
                                        {...file}
                                        onDownload={() => handleDownload(undefined, file.name)}/>
                            </Grid>
                    })
                }
            </Grid>
        </>
}

export default Folders;