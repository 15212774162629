
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFoundPage from './utils/NotFoundPage';
import ErrorPage from './utils/ErrorPage';
import Main from './main/Main';
import Login from './authentication/Login';
import theme from './theme';
import Providers from './context/Providers';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import ProtectedRoute from './authentication/ProtectedRoute';

const App: React.FC<{}> = () => {
    return <ThemeProvider theme={theme} >
                <CssBaseline />
                    <BrowserRouter>
                        <Providers>
                            <Routes>
                                <Route index element={<Login />} errorElement={<ErrorPage />} />
                                <Route path='/home/:option?' element={<Login />} errorElement={<ErrorPage />} />
                                <Route path='/main' element={<ProtectedRoute><Main /></ProtectedRoute>} errorElement={<ErrorPage />} />
                                <Route path='*' element={<NotFoundPage />} />
                            </Routes>
                    </Providers>
                </BrowserRouter>
            </ThemeProvider>
}

export default App;
