import {
    Chip,
    Avatar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    chipColor: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        fontWeight: 'bolder',
        '& div': {
            backgroundColor: theme.palette.text.primary,
        },
        '& .MuiAvatar-root': {
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 'bolder'
        },
        '& .MuiChip-label': {
            paddingTop: '2px !important',
            fontSize: '0.9rem !important'
        }
    }
}))

interface IUserInfo {
    name: string,
    company: string
}

const UserInfo: React.FC<IUserInfo> = (props) => {

    const { classes } = useStyles();

    return (
        <Chip
            avatar={<Avatar>{props.name}</Avatar>}
            label={props.company}
            className={classes.chipColor}
            variant="outlined"
        />
    );
}

export default UserInfo;