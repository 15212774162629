import { useState, createContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IAlertInfo, IInfo, initialState } from './models/infoProviderModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    infoColored: {
        color: theme.palette.secondary.main
    }
}));

export const InfoMessageContext = createContext<{
    info: IInfo;
    setInfo: (a: IInfo) => void;
}>({
    info: initialState,
    setInfo: (a: IInfo) => { }
});


const AlertInfo: React.FC<IAlertInfo> = ({ info, handleClose }) => {

    const { classes } = useStyles();

    return <Dialog
                open={ info.hasInfo }
                onClose={ handleClose }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className={classes.infoColored}>
                    { info.title }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { info.message }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.infoColored} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
};

const InfoProvider: React.FC<{ children: any }> = ({ children }) => {
    
    const [info, setInfo] = useState<IInfo>(initialState);

    const handleClose = () => {
        setInfo({
            ...info,
            hasInfo: false
        })
    }

    return (
        <InfoMessageContext.Provider value={{ info, setInfo }}>
            { children }
            <AlertInfo
                info={info}
                handleClose={handleClose} /> 
        </InfoMessageContext.Provider>
    )
}

export default InfoProvider;