import { useState } from 'react';
import { Link, Grid, Paper, Box, Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Recover from './Recover';
import Copyright from '../utils/Copyright';
import ConfirmRecoverdPassword from './ConfirmRecoverdPassword';

import logo from '../assets/imgs/miniwhitelogo.png';
import background from '../assets/imgs/background.png';

const useStyles = makeStyles()((theme) => ({
    backImg: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '100%',
      height: '100vh',
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        display: 'none'
      }
    },
    appLogo: {
      height: '25vmin',
      pointerEvents: 'none',
      marginBottom: '30px'
    },
    loginContainer: {
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      '-ms-transform': 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
      maxWidth: '550px',
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        maxWidth: '400px'
      }
    },
    logo: {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
        display: 'none'
      }
    },
    paper: {
      backgroundColor: 'rgba(255,255,255,0.8) !important'
    },
    divPaper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      marginBottom: theme.spacing(3)
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    signup: {
      color: '#FFFFFF'
    },
    recover: {
      color: '#FFFFFF',
      float: 'right'
    },
    confirmationTitle: {
      color: theme.palette.primary.main
    },
    copyright: {
      color: theme.palette.text.primary,
      margin: '0',
      position: 'absolute',
      top: '93%',
      left: '50%;',
      transform: 'translate(-50%, -7%)'
    }
}));

const LOGIN_STATE: readonly [string,string,string] = ['SignIn','SignUp','Recover']

const Login: React.FC<{}> = () => {

    const { classes } = useStyles();

    const [loginState, setLoginState] = useState<string>(LOGIN_STATE[0]);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [show, setShow] = useState<boolean>(false);
    const [demoMode, setDemoMode] = useState<boolean>(false);

    const { option } = useParams();
    
    const gotToRecover = () => {
        setLoginState(LOGIN_STATE[2])
      }
    
      const goToSignUp = () => {
        setLoginState(LOGIN_STATE[1])
      }
    
      const goToSignIn = (title?: string, message?: string) => {
        setLoginState(LOGIN_STATE[0])

        if(title || message) {
          setTitle(title);
          setMessage(message);
          setShow(true);
        }
      }

     return <div className={'classes.backgroundLogin'}>
          <img className={classes.backImg} src={background} alt='background' />
          <Container className={classes.loginContainer} component='main' maxWidth='sm'>
            <Grid container justifyContent='center' className={classes.logo}>
              <img src={logo} className={classes.appLogo} alt='logo' />
            </Grid>
            { 
              loginState !== LOGIN_STATE[0]  ? 
              <Box mb={2}>
                  <Grid container item xs>
                      <Link variant='body2' component='button' className={classes.recover} onClick={() => goToSignIn()}>
                          {'<'} Return to Sign In
                      </Link>
                  </Grid>
              </Box>
              : <></>
            }
            <Paper className={classes.paper} elevation={8}>
              <Box p={4} pb={2} pt={2}>
              <div className={classes.divPaper}>
                {
                  loginState === LOGIN_STATE[0] ? <SignIn option={option!} demoMode={demoMode} setDemoMode={setDemoMode} /> : null
                }
                {
                  loginState === LOGIN_STATE[2] ? <Recover goToSignIn={goToSignIn} demoMode={demoMode} /> : null
                }
                {
                  loginState === LOGIN_STATE[1] ? <SignUp goToSignIn={goToSignIn} demoMode={demoMode} /> : null
                }
              </div>
            </Box>
            </Paper>
            { 
              loginState === LOGIN_STATE[0] ? 
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Link variant='body2' component='button' className={classes.signup} onClick={goToSignUp}>
                        Register
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Link variant='body2' component='button' className={classes.recover} onClick={gotToRecover}>
                        Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              : <></>
            }
            <ConfirmRecoverdPassword
              show={show}
              setShow={setShow}
              title={title}
              message={message} />
          </Container>
          <Box className={classes.copyright} >
            <Copyright />
          </Box>
        </div>;
}

export default Login;
