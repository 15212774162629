import { useState } from 'react';
import {
    Dropzone,
    ExtFile,
    FileMosaic,
    FullScreen,
    ImagePreview
  } from '@files-ui/react';
import { makeStyles } from 'tss-react/mui';
import { ws } from '../../../ws/call';

const useStyles = makeStyles()((theme) => ({
    fileUpload: {
        padding: '10px'
    }
}));

interface IFileRepoUpload {
	token: string,
	handleClose: () => void;
}
  
const FileRepoUpload: React.FC<IFileRepoUpload> = (props) => {

    const { classes } = useStyles();

    const [extFiles, setExtFiles] = useState<ExtFile[]>([]);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  
    const updateFiles = (incommingFiles: ExtFile[]) => {
      setExtFiles(incommingFiles);
    };

    const onDelete = (id: string | number | undefined) => {
      setExtFiles(extFiles.filter((x) => x.id !== id));
    };

    const handleSee = (imageSource: string | undefined) => {
      setImageSrc(imageSource);
    };
    
    const handleStart = (filesToUpload: ExtFile[]) => {
      //console.log('advanced demo start upload', filesToUpload);
    };

    const handleFinish = (uploadedFiles: ExtFile[]) => {
		if(uploadedFiles.every(file => file.serverResponse?.success)) {
			props.handleClose();
		}
    };

    const handleAbort = (id: string | number | undefined) => {
      setExtFiles(
        extFiles.map((ef) => {
          if (ef.id === id) {
            return { ...ef, uploadStatus: 'aborted' };
          } else return { ...ef };
        })
      );
    };

    const handleCancel = (id: string | number | undefined) => {
      setExtFiles(
        extFiles.map((ef) => {
          if (ef.id === id) {
            return { ...ef, uploadStatus: undefined };
          } else return { ...ef };
        })
      );
    };

    return (
      <div className={classes.fileUpload}>
        <Dropzone
          onChange={updateFiles}
          minHeight='195px'
          value={extFiles}
          accept='image/*, application/*, text/*'
          maxFiles={5}
          maxFileSize={10 * 1024 * 1024}
          label='Drag n drop files here or click to browse'
          onUploadStart={handleStart}
          onUploadFinish={handleFinish}
          uploadConfig={ws.uploadFiles(props.token)}
          actionButtons={{
            position: 'after',
            abortButton: {},
            deleteButton: {},
            uploadButton: {}
          }}
        >
          {extFiles.map((file) => (
            <FileMosaic
              {...file}
              key={file.id}
              onDelete={onDelete}
              onSee={handleSee}
              onAbort={handleAbort}
              onCancel={handleCancel}
              resultOnTooltip
              alwaysActive
              preview
              info
            />
          ))}
        </Dropzone>
        <FullScreen
          open={imageSrc !== undefined}
          onClose={() => setImageSrc(undefined)}>
          <ImagePreview src={imageSrc} />
        </FullScreen>
      </div>
    );
  }
  
  export default FileRepoUpload;
