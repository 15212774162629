export enum CONFIRMATION_TYPES {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    CONFIRMATION = 'CONFIRMATION'
}

export interface IAlertConfirm {
    open: boolean,
    handleClose: () => void,
    agreeButton: string,
    title: string,
    content: string,
    handleDisagree: () => void,
    disagree: string,
    handleAgree: () => void
}

export interface IConfirmationMsg {
    hasConfirmationMsg: boolean,
    title: string,
    content: string,
    confirmationType: CONFIRMATION_TYPES,
    agree: () => void
}

export const initialState: IConfirmationMsg = {
    hasConfirmationMsg: false,
    title: '',
    content: '',
    confirmationType: CONFIRMATION_TYPES.CONFIRMATION,
    agree: () => {}
}