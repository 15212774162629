import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { makeStyles } from 'tss-react/mui';
import { 
    CssBaseline, Drawer, AppBar, Toolbar, List, Container, Typography, Divider, IconButton, Paper
} from  '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { SnackbarProvider, useSnackbar } from 'notistack';

import NoMenu from '../utils/NoMenu';
import LogOut from '../utils/LogOut';
import UserInfo from './UserInfo';

import { IMenu, MENUS } from './models/menulistitemModel';
import { MenuList, Version } from './MenuListItem';
import { IAuthContext } from '../context/models/authModel';
import { IUserData } from '../ws/models/userModels';

const DRAWER_WITH = 300;

const useStyles = makeStyles()((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px'
    },
    notMobile: {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        display: 'none'
      }
    },
    badge: {
      color: theme.palette.secondary.main
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: DRAWER_WITH,
      width: `calc(100% - ${DRAWER_WITH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarSpacer: {
      paddingTop: '0',
    },
    titleButton: {
      verticalAlign: 'middle',
      marginRight: '0.5em'
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
      float: 'right'
    },
    drawerPaper: {
      position: 'relative',
      paddingTop: '25px',
      whiteSpace: 'nowrap',
      width: DRAWER_WITH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      backgroundColor: theme.palette.secondary.light,
      paddingBottom: '50px',
      paddingTop: '65px'
    },
    container: {
      backgroundColor: theme.palette.secondary.light,
      width: '100%',
      maxWidth: '1600px !important',
      padding: theme.spacing(5)
    },
    paper: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      width: '100%',
      padding: '20px'
    },
    fixedHeight: {
      height: 240,
    },
    selectedItem: {
      '& span': {
        fontWeight: 'bolder',
        color: theme.palette.primary.main
      }
    },
    bottomCopyright: {
      position: 'absolute',
      bottom: '30px'
    }
  }));

const selectFirstMenu = (state: IAuthContext) => {
  // console.log(MENUS)
  // console.log(state.info)
  let menu = MENUS.find(m => state.info![m.userProp as keyof IUserData]);
  return menu ?? MENUS[0];
}

const Main: React.FC<{}> = () => {

  const { classes } = useStyles();

  const { state } = useContext(AuthContext);

  const [open, setOpen] = useState<boolean>(false);
  const [menu, setMenu] = useState<IMenu>(selectFirstMenu(state));

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeMenu = (m: IMenu) => {
    setMenu(m);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={`${classes.appBar} ${open && classes.appBarShift}`}>
        <Toolbar className={classes.toolbar}>
          {
            menu &&
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              className={`${classes.menuButton} ${open && classes.menuButtonHidden}`}>
              <MenuIcon />
            </IconButton>
          }
          <Typography component='h1' variant='h6' color='inherit' noWrap className={`${classes.title} ${classes.notMobile}`}>
            <menu.icon className={classes.titleButton} />
            {menu && menu.label}
          </Typography>
          <UserInfo name={state.info!.avatar} company={state.info!.empleadoData?.name!} />
          {/* <Settings /> */}
          {/* <Notifications /> */}
          <LogOut />
        </Toolbar>
      </AppBar>
      {
        menu && <Drawer
          variant='permanent'
          classes={{ paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose}`}}
          open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <MenuList menu={menu} changeMenu={changeMenu} />
          </List>
          {/* {
            state.info!.master && <Divider />
          }
          <List>
            <SubMenuList menu={menu} changeMenu={changeMenu} />
          </List>
          {
            state.info!.backoffice && <Divider />
          }
          <List>
            <BackMenuList menu={menu} changeMenu={changeMenu} />
          </List> */}
          <Version />
        </Drawer>
      }
      <SnackbarProvider maxSnack={7}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>
            <Paper className={classes.paper}>
              {menu ? <menu.component useSnackbar={useSnackbar} changeMenu={changeMenu} /> : <NoMenu />}
            </Paper>
          </Container>
        </main>
      </SnackbarProvider>
    </div>
  );
}

export default Main;