import { useState, createContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { initialState, IAction, IAlertIStaticOptions, IStaticOptions } from './models/staticOptionsModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    confirmationTitle: {
        color: theme.palette.primary.main
    },
    disagree: {
        color: theme.palette.text.disabled
    },
    errorTitle: {
        color: theme.palette.error.main
    },
    warningTitle: {
        color: theme.palette.info.main
    }
}));

export const StaticOptionsContext = createContext<{
    staticOptions: IStaticOptions;
    setStaticOptions: (a: IStaticOptions) => void;
}>({
    staticOptions: initialState,
    setStaticOptions: (a: IStaticOptions) => { }
});


const AlertConfirm: React.FC<IAlertIStaticOptions> = ({ staticOptions, handleClose }) => {

    const { classes } = useStyles();

    return <Dialog
                open={ staticOptions.hasStaticOptions }
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' className={classes.confirmationTitle}>
                    { staticOptions.title }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                    { staticOptions.content }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={ handleClose } 
                        className={classes.disagree}>
                        Cancel
                    </Button>
                    {
                        staticOptions.actions ?
                            staticOptions.actions.map((act: IAction, idx: number) => {
                                return <Button
                                            key={idx}
                                            onClick={() => { act.action(); handleClose(); }} 
                                            className={classes.warningTitle}>
                                            { act.name }
                                        </Button>
                            }) : null
                    }
                </DialogActions>
            </Dialog>
}

const StaticOptionsProvider: React.FC<{ children: any }> = ({ children }) => {

    const [staticOptions, setStaticOptions] = useState<IStaticOptions>(initialState);

    const handleClose = () => {
        setStaticOptions({
            ...staticOptions,
            hasStaticOptions: false
        });
    }

    return <StaticOptionsContext.Provider value={{ staticOptions, setStaticOptions }}>
                { children }
                <AlertConfirm
                    staticOptions={staticOptions}
                    handleClose={handleClose} />
            </StaticOptionsContext.Provider>
}

export default StaticOptionsProvider;