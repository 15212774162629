import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { Box, Grid, Card, CardContent, Typography, CardMedia, ImageList, ImageListItem, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, CardHeader, Button } from '@mui/material';
import DashboardItem from './dashboard/DashboardItem';
import { PhoneAndroid as PhoneAndroidIcon, Email as EmailIcon, Home as HomeIcon, Group as GroupIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import ButtonMailTo from '../../utils/ButtonMailTo';
import calendario from '../../assets/imgs/Calendario.jpg';
import { ws } from '../../ws/call';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';
import { IAbsent } from '../../ws/models/absentsModels';
import { IEmpleado } from '../../ws/models/empleadoModels';
import { getStringDate } from '../../helpers/date';

const useStyles = makeStyles()((theme) => ({
    card: {
        minWidth: '275px',
        backgroundColor: 'transparent',
        boxShadow: 'none !important',
        display: 'flex'
    },
    boxTitle: {
        color: 'primary !important'
    },
    img: {
        width: '151px',
        borderRadius: '10%'
    },
    grid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    },
    box: {
        width: '100%',
    },
    calendario: {
        textAlign: 'center',
        width: '90%'
    },
    typographyTop: {
        fontSize: '14px'
    },
    typographyBottom: {
        marginBottom: theme.spacing(2)
    },
    title: {
        fontWeight: 'bold'
    },
    sectionTitle: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: '20px'
    },
    miniTable: {
        width: '50%'
    },
    mediumTable: {
        width: '100%'
    }
}));

const DashboardV2: React.FC<{}> = () => {

    const { classes } = useStyles();

    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);

    const [empleado, setEmpleado] = useState<IEmpleado>();
    const [absent, setAbsent] = useState<IAbsent | undefined>(undefined);

    useEffect(() => {
        setEmpleado(userInfo!.empleadoData);
        callAbsent(new Date().getFullYear());
    }, []);

    const callAbsent = (year: number) => {
        setLoading(true);

        ws.getAbsent(year, userInfo!.customerId, token!)
            .then((a: IAbsent) => {
                setAbsent(a);
            })
            .catch((err: any) => {
                setError({
                    hasError: true,
                    title: 'Absents Error - Dashboard Absent',
                    message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getPropObj = (label: string, name: string, shortName?: string): { name: string, label: string, value: string } => ({
        label,
        name,
        value: `${'getProp(name)'} ${(shortName ? ('(' + 'getProp(shortName)' + ')') : '')}`

    })

    const getProp = (name: string): string => {
        return empleado![name as keyof Omit<IEmpleado, 'personalData' | 'contacts' | 'department'>];
    }

    const phoneJSX = (phone: string) => {
        if (phone) {
            return <><PhoneAndroidIcon color='secondary' /> {phone}</>
        } else {
            return null;
        }
    }

    const emailJSX = (email: string) => {
        if (email) {
            return <><EmailIcon color='secondary' /> {email}</>
        } else {
            return null;
        }
    }

    const dowloadFile = () => {
        setLoading(true);

        ws.downloadDocs('Calendario.pdf', token!)
            .then((href: string) => {
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Calendario'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err: any) => {
                setError({
                    hasError: true,
                    title: 'File Error',
                    message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    if (empleado) {
        return <>
            <Card className={classes.card}>
                <CardMedia
                    component="img"
                    className={classes.img}
                    src={`data:${userInfo!.empleadoData?.photoMime};base64, ${userInfo!.empleadoData?.photo}`}
                    alt="No image"
                />
                <CardContent>
                    <Typography variant='h5' component='div' color='primary'>
                        {userInfo?.empleadoData?.name}
                    </Typography>
                    <Typography className={classes.typographyBottom} color='secondary'>
                        {userInfo?.empleadoData?.id}
                    </Typography>
                </CardContent>
            </Card>
            <Grid container spacing={2} className={classes.grid}>
                <DashboardItem
                    xs={12}
                    name={'Centro'}
                    label={'Centro'}
                    value={empleado.personalData!.centro} />
                <DashboardItem
                    xs={12}
                    name={'Departamento'}
                    label={'Departamento'}
                    value={`${empleado.department!.name}`} />
                <DashboardItem
                    xs={12}
                    name={'Fecha de Nacimiento'}
                    label={'Fecha de Nacimiento'}
                    value={getStringDate(empleado.personalData.birth)} />
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                    <Box className={classes.box}>
                        <Button variant='text' disableRipple className={classes.boxTitle} startIcon={<HomeIcon color='primary' />}>
                            DIRECCIONES 
                        </Button>
                        <CardContent style={{paddingTop: 0}}>
                            <Typography variant='body2' component='div'>
                                {empleado.contacts.address}
                            </Typography>
                            <Typography variant='body2'>
                                {empleado.contacts.postalCode}, {empleado.contacts.city} | {empleado.contacts.state}
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box className={classes.box}>
                        <Button variant='text' disableRipple className={classes.boxTitle} startIcon={<PhoneAndroidIcon color='primary' />}>
                            DATOS DE CONTACTOS
                        </Button>
                        <CardContent style={{paddingTop: 0}}>
                            <Typography variant='body2' component='div'>
                                {empleado.contacts.phone}
                            </Typography>
                            <Typography variant='body2'>
                                {userInfo?.email}
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box className={classes.box}>
                       <Button variant='text' disableRipple className={classes.boxTitle} startIcon={<PhoneAndroidIcon color='primary' />}>
                            DEPARTAMENTO
                        </Button>
                        <CardContent style={{paddingTop: 0}}>
                            <Typography variant='body2' component='div'>
                                {empleado.department.name} | {empleado.department.shiftDesc} 
                            </Typography>
                            <Typography variant='body2'>
                                {empleado.department.supervisor}
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box className={classes.box}>
                        <Typography className={classes.sectionTitle} variant="overline" component="h5">
                            CALENDARIO
                        </Typography>
                        {/* <img src={calendario} /> */}
                        <ImageList cols={1} gap={8}>
                            <ImageListItem className={classes.calendario} onClick={dowloadFile}>
                                <img
                                    src={calendario}
                                    alt='No Calendario'
                                    loading="lazy" />
                            </ImageListItem>
                        </ImageList>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className={classes.box}>
                                <Typography className={classes.sectionTitle} variant="overline" component="h5">
                                    DIAS DISPONIBLES DE VACACCIONES Y ASUNTOS PROPIOS
                                </Typography>
                                {
                                    absent ?
                                        <>
                                            <TableContainer className={classes.mediumTable}>
                                                <Table sx={{ minWidth: 180 }} component={Paper} size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center' className={classes.title}>Vacaciones {absent.year}</TableCell>
                                                            <TableCell align='center' className={classes.title}></TableCell>
                                                            <TableCell align='center' className={classes.title}>Vacaciones {absent.year - 1}</TableCell>
                                                            <TableCell align='center' className={classes.title}></TableCell>
                                                            <TableCell align='center' className={classes.title}>Horas Consumidas</TableCell>
                                                            <TableCell align='center' className={classes.title}></TableCell>
                                                            <TableCell align='center' className={classes.title}>Horas Disponibles</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell align='center'>{absent.vacationsCurYear}</TableCell>
                                                            <TableCell align='center'>+</TableCell>
                                                            <TableCell align='center'>{absent.vacationsLastYear}</TableCell>
                                                            <TableCell align='center'>-</TableCell>
                                                            <TableCell align='center'>{absent.vacationsTotal}</TableCell>
                                                            <TableCell align='center'>=</TableCell>
                                                            <TableCell align='center'>{absent.vacationsAvailable}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <br />
                                            <Typography className={classes.sectionTitle} variant="overline" component="h5">
                                                HORAS CONSUMIDAS
                                            </Typography>
                                            <TableContainer className={classes.mediumTable}>
                                                <Table sx={{ minWidth: 180 }} component={Paper} size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center' className={classes.title}>Vacaciones (Hrs)</TableCell>
                                                            <TableCell align='center' className={classes.title}>Asuntos Propios (Hrs)</TableCell>
                                                            <TableCell align='center' className={classes.title}>Licencias (Hrs)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell align='center'>{absent.vacationsTotal - absent.totalAbsent - absent.totalLicense}</TableCell>
                                                            <TableCell align='center'>{absent.totalAbsent}</TableCell>
                                                            <TableCell align='center'>{absent.totalLicense}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </> : null
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <br /><br />
                            <Box className={classes.box}>
                                <Typography className={classes.sectionTitle} variant="overline" component="h5">
                                    DATOS DE CONTACTO RRHH
                                </Typography>
                                <Typography variant='h6' component='div'>
                                    <ButtonMailTo label="prevrgos@liderkit.com" mailto="mailto:prevrgos@liderkit.com" />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    return null;
}

export default DashboardV2;