import { useState, useContext } from 'react';
import { Button, LinearProgress, TextField,InputAdornment, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';

import { ErrorMessageContext } from '../context/ErrorMessageProvider';
import { InfoMessageContext } from '../context/InfoProvider';
import { makeStyles } from 'tss-react/mui';

import { isEmail } from '../helpers/check';
import { ws } from '../ws/call';

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    whiteInput: {
        backgroundColor: 'white'
    },
    title: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            marginBottom: theme.spacing(0),
            fontSize: '1.2rem'
        }
    },
    pintitle: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            marginBottom: theme.spacing(0),
            fontSize: '1.2rem'
        }
    },
    confirmPassword: {
        marginTop: theme.spacing(0),
        [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
            marginBottom: theme.spacing(0),
            fontSize: '1.2rem'
        }
    },
    pinInput: {
        backgroundColor: 'white',
        marginTop: theme.spacing(0)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    loadingLinear: {
        margin: theme.spacing(4, 0, 4, 0)
    }
}));

const RECOVER_MODE: [string, string, string] = ['email', 'pin', 'newpassword']

interface IRecover {
    demoMode: boolean,
    goToSignIn: (a: string, b: string) => void
}

const Recover: React.FC<IRecover> = (props) => {
    const { classes } = useStyles();

    const { setError } = useContext(ErrorMessageContext);
    const { setInfo } = useContext(InfoMessageContext);

    const [recoverMode, setRecoverMode] = useState<string>(RECOVER_MODE[0])

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);

    const [pinCode, setPinCode] = useState<string>('');
    const [checkingPin, setCheckingPin] = useState<boolean>(false);

    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [sendingNewPassword, setSendingNewPassword] = useState<boolean>(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<string>('');

    const handleChange = (ev: any) => {
        let { value } = ev.target;

        setEmail(value);
        if(value) {
            setEmailError('');
            setRecoverMode(RECOVER_MODE[0]);
        }
    }

    const handleRecover = (ev: any) => {
        ev.preventDefault();

        if(props.demoMode) {
            setError({
                hasError: true,
                title: 'Demo Mode',
                message: 'Can\'t perform this operation in Demo Mode'
            })
        }

        if(!email) {
            setEmailError('Missing');
            return;
        }

        if(!isEmail(email)) {
            setEmailError('Not an email');
            return;
        }

        setSendingEmail(true);

        ws.sendRecoverPin(email)
            .then(ok => {
                setInfo({
                    hasInfo: true,
                    title: `Pin Code Sent`,
                    message: `Pin code was sent to your email. Check your email inbox or spam.`
                });
                setRecoverMode(RECOVER_MODE[1]);
            })
            .catch(err => {
                setError({
                    hasError: true,
                    title: 'Recover Pin Error',
                    message: err.message
                  });
            })
            .finally(() => {
                setSendingEmail(false);
            })
    }

    const handlePinChange = (ev: any) => {

        if(props.demoMode) {
            setError({
                hasError: true,
                title: 'Demo Mode',
                message: 'Can\'t perform this operation in Demo Mode'
            })
        }

        let { value } = ev.target;

        if(/^[0-9]{0,6}$/.test(value)) {
            setPinCode(value);
        }

        if(/^[0-9]{6}$/.test(value)) {
            checkPin(value);
        }
    }

    const checkPin = (pinCode: number) => {

        setCheckingPin(true);

        ws.checkRecoverPin(email, pinCode)
            .then(ok => {
                // props.gotToSignIn('New Recover Pin was Sent!', 'Check your email for a new ');
                setRecoverMode(RECOVER_MODE[2]);
            })
            .catch(err => {
                setError({
                    hasError: true,
                    title: 'Check Pin Error',
                    message: err.message
                  });
            })
            .finally(() => {
                setCheckingPin(false);
            })
    }

    const handleNewPassword = (ev: any) => {

        if(props.demoMode) {
            setError({
                hasError: true,
                title: 'Demo Mode',
                message: 'Can\'t perform this operation in Demo Mode'
            })
        }

        let { value } = ev.target;

        setNewPassword(value);
        if(value) {
            setNewPasswordError('');
            setConfirmNewPasswordError('');
        }
    }

    const handleConfirmNewPassword = (ev: any) => {

        if(props.demoMode) {
            setError({
                hasError: true,
                title: 'Demo Mode',
                message: 'Can\'t perform this operation in Demo Mode'
            })
        }

        let { value } = ev.target;

        setConfirmNewPassword(value);
        if(value) {
            setConfirmNewPasswordError('');
        }
    }

    const handleChangePassword = (ev: any) => {

        if(props.demoMode) {
            setError({
                hasError: true,
                title: 'Demo Mode',
                message: 'Can\'t perform this operation in Demo Mode'
            })
        }

        ev.preventDefault();

        if(!newPassword) {
            setNewPasswordError('Password Empty!');
            return;
        }

        if(newPassword.length < 6) {
            setNewPasswordError('Short password!');
            return;
        }

        if(newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError('Password do not match!');
            return;
        }

        setSendingNewPassword(true);

        ws.recoverCustomer(email, pinCode, confirmNewPassword)
            .then(ok => {
                props.goToSignIn('Please Login Again', 'New Password was created!');
            })
            .catch(err => {
                setError({
                    hasError: true,
                    title: 'Change Password Error',
                    message: err.message
                  });
            })
            .finally(() => {
                setSendingNewPassword(false);
            })
    }

    return <>
            <Typography variant="h5" color="primary" className={classes.title}>
                    Reset Password Account
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        className={classes.whiteInput}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        disabled={recoverMode === RECOVER_MODE[1] || recoverMode === RECOVER_MODE[2]}
                        error={emailError ? true : false}
                        helperText={emailError}
                        onChange={handleChange}
                        autoFocus
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person color="primary" />
                              </InputAdornment>
                            ),
                          }}
                    />
                    {
                        recoverMode === RECOVER_MODE[0] && !sendingEmail ? 
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRecover}
                                    className={classes.submit}>
                                    Reset Password
                                </Button> : null
                    }
                    {
                        recoverMode === RECOVER_MODE[0] && sendingEmail ? 
                            <LinearProgress className={classes.loadingLinear} /> : null
                    }
                    {
                        (recoverMode === RECOVER_MODE[1] && !checkingPin) || recoverMode === RECOVER_MODE[2] ? 
                            <>
                                <Typography variant="button" color="primary" className={classes.pintitle}>Insert Pin</Typography>
                                <TextField
                                    className={classes.pinInput}
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    id="pincode"
                                    name="pincode"
                                    type="text"
                                    value={pinCode}
                                    disabled={recoverMode === RECOVER_MODE[0] || recoverMode === RECOVER_MODE[2]}
                                    onChange={handlePinChange}
                                />
                            </> : null
                    }
                    {
                        recoverMode === RECOVER_MODE[1] && checkingPin ? 
                            <LinearProgress className={classes.loadingLinear} /> : null
                    }
                    {
                        recoverMode === RECOVER_MODE[2] ? 
                            <>
                                <Typography variant="button" color="primary" className={classes.pintitle}>Insert New Password</Typography>
                                <TextField
                                    className={classes.pinInput}
                                    variant="outlined"
                                    margin="normal"
                                    size="medium"
                                    fullWidth
                                    id="newpassword"
                                    name="newpassword"
                                    type="password"
                                    error={newPasswordError ? true : false}
                                    helperText={newPasswordError}
                                    disabled={sendingNewPassword}
                                    value={newPassword}
                                    onChange={handleNewPassword}
                                />
                                <Typography variant="button" color="primary" className={classes.confirmPassword}>Confirm New Password</Typography>
                                <TextField
                                    className={classes.pinInput}
                                    variant="outlined"
                                    margin="normal"
                                    size="medium"
                                    fullWidth
                                    id="confirmnewpassword"
                                    name="confirmnewpassword"
                                    type="password"
                                    error={confirmNewPasswordError ? true : false}
                                    helperText={confirmNewPasswordError}
                                    disabled={sendingNewPassword}
                                    value={confirmNewPassword}
                                    onChange={handleConfirmNewPassword}
                                />
                            </> : null
                    }
                    {
                        recoverMode === RECOVER_MODE[2] ? 
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleChangePassword}
                                    className={classes.submit}>
                                    Save Password
                                </Button> : null
                    }
                </form>
            </>
}

export default Recover;