export const initialState: IError = {
    hasError: false,
    title: '',
    message: ''
}

export interface IAlertError {
    error: IError,
    handleClose: (a: IError) => void
}

export interface IError {
    hasError: boolean,
    title: string,
    message: string
}