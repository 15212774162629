export interface IUnavailableDay {
    day: Date,
    description: string
}

export const ABSENTTYPES = ['Vacaciones', 'Ausencia', 'Licencia'] as const;
export type TAbsentType = typeof ABSENTTYPES[number];

export interface IAggrAbsentLines {
    day: {
        hours: number,
        type: TAbsentType
    }
}

export interface IAbsentLine {
    id: number,
    date: Date,
    dateStr: string,
    type: number,
    typeStr: string,
    motive: string,
    motiveStr: string,
    duration: number,
    apprResponsable: boolean,
    apprHR: boolean
}

export interface IAbsent {
    id: string,
    year: number,
    totalAbsent: number,
    totalLicense: number,
    vacationsLastYear: number,
    vacationsCurYear: number,
    vacationsTotal: number,
    vacationsAvailable: number,
    absentLines: IAbsentLine[]
}

export interface IAbentMotive {
    code: string,
    description: string,
    group: string
}

export interface INewAbsentLine {
    type: number,
    motive: string,
    date: string,
    duration: number
}