import { Chip, Avatar } from '@mui/material';

const NoMenu: React.FC<{}> = () => {
    return <Chip
                avatar={<Avatar>NM</Avatar>}
                label="No Menu"
                variant="outlined"
                color="primary" />
}

export default NoMenu;