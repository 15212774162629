import DashboardV2 from '../menus/DashboardV2';
import FileRepository from '../menus/FileRepository';
import InOutCheck from '../menus/InOutCheck';
import FollowUps from '../menus/FollowUps';
import SalaryReceipt from '../menus/SalaryReceipt';
import Folders from '../menus/Folders';
import Absents from '../menus/Absents';

import { 
    Person as PersonIcon,
    Folder as FolderIcon,
    DriveFolderUpload as DriveFolderUploadIcon,
    HistoryToggleOffOutlined,
    ClearAllOutlined,
    EventNote as EventNoteIcon,
    Receipt as ReceiptIcon,
    Event as EventIcon } from '@mui/icons-material';
import IntroducirPartes from '../menus/IntroducirPartes';

export const DASHBOARD = 'Detalle',
    YPDASHBOARD = 'XJABPC',
    LDASHBOARD = 'dashboard',
    CDASHBOARD = 'Dashboard';
export const FILEREPO = 'Repositorio',
    YPFILEREPO = 'FILEREPO',
    LFILEREPO = 'filerepo',
    CFILEREPO = 'FileRepository';
export const FOLDERS = 'Carpetas',
    YPFOLDERS = 'FOLDERS',
    LFOLDERS = 'folders',
    CFOLDERS = 'Folders';
export const INOUCHECK = 'Biometricos',
    YPINOUCHECK = 'INOUCHECK',
    LINOUCHECK= 'inoutcheck',
    CINOUCHECK= 'InOutCheck';
export const FOLLOWUPS = 'Seguimientos',
    YPFOLLOWUPS = 'FOLLOWUPS',
    LFOLLOWUPS = 'followups',
    CFOLLOWUPS = 'FollowUps';
export const WORKLOADS = 'Introducir Partes',
    YWORKLOADS = 'WORKLOADS',
    LWORKLOADS = 'workloads',
    CWORKLOADS = 'WorkLoads';
export const SALARYRECEIPT = 'Nominas',
    YPSALARYRECEIPT = 'SALARYRECEIPT',
    LSALARYRECEIPT = 'salaryreceipt',
    CSALARYRECEIPT = 'Nominas';
export const ABSENTS = 'Asuntos Propios',
    YPABSENTS = 'ABSENTS',
    LABSENTS = 'absents',
    CABSENTS = 'Asuntos Propios';

export interface IMenu {
    label: string,
    x3label: string,
    userProp: string,
    icon: any,
    component: any,
    help: string
}

export interface IMenuList {
    menu: IMenu,
    changeMenu: (a: IMenu) => void;
}

export const MENUS: IMenu[] = [
    {
        label: DASHBOARD,
        x3label: YPDASHBOARD,
        userProp: LDASHBOARD,
        icon: PersonIcon,
        component: DashboardV2,
        help: CDASHBOARD,
    },
    {
        label: FILEREPO,
        x3label: YPFILEREPO,
        userProp: LFILEREPO,
        icon: DriveFolderUploadIcon,
        component: FileRepository,
        help: CFILEREPO,
    },
    {
        label: FOLDERS,
        x3label: YPFOLDERS,
        userProp: LFOLDERS,
        icon: FolderIcon,
        component: Folders,
        help: CFOLDERS,
    },
    {
        label: FOLLOWUPS,
        x3label: YPFOLLOWUPS,
        userProp: LFOLLOWUPS,
        icon: ClearAllOutlined,
        component: FollowUps,
        help: CFOLLOWUPS,
    },
    {
        label: WORKLOADS,
        x3label: YWORKLOADS,
        userProp: LWORKLOADS,
        icon: EventNoteIcon,
        component: IntroducirPartes,
        help: CWORKLOADS,
    },
    {
        label: SALARYRECEIPT,
        x3label: YPSALARYRECEIPT,
        userProp: LSALARYRECEIPT,
        icon: ReceiptIcon,
        component: SalaryReceipt,
        help: CSALARYRECEIPT,
    },
    {
        label: ABSENTS,
        x3label: YPABSENTS,
        userProp: LABSENTS,
        icon: EventIcon,
        component: Absents,
        help: CABSENTS,
    },
    {
        label: INOUCHECK,
        x3label: YPINOUCHECK,
        userProp: LINOUCHECK,
        icon: HistoryToggleOffOutlined,
        component: InOutCheck,
        help: CINOUCHECK,
    }
];

// Menus com permissões admin
const ACCOUNTS = 'Accounts';
export const SUBMENUS: IMenu[] = [
    // { 
    //     label: ACCOUNTS,
    //     x3label: '',
    //     userProp: '',
    //     icon: SupervisorAccount,
    //     component:
    //     Accounts, 
    //     help: '' 
    // },
];

// Menus backoffice
const BACKOFFICE = 'BackOffice';
export const BACKMENUS: IMenu[] = [
    // { 
    //     label: BACKOFFICE,
    //     x3label: '',
    //     userProp: '',
    //     icon: HomeWork,
    //     component: BackOffice,
    //     help: '' 
    // },
];