import { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { ExtFile } from '@files-ui/react';
import { reorderAndFilterFiles } from '../../helpers/fileorder';
import { ws } from '../../ws/call';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: '2px 4px',
        margin: '0 0 10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 40px)'
    },
    iconBtn: {
        padding: '10px'
    },
    divider: {
        height: 28,
        margin: 0.5
    },
    inputBase: {
        margin: 1,
        paddingLeft: '20px',
        flex: 1
    },
    table: {
        width: '100%'
    },
    tablerow: {
        '&:last-child td, &:last-child th': {
            border: '0'
        }
    }
}));

const SalaryReceipt: React.FC<{}> = () => {

    const { classes } = useStyles();

    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);

    const [files, setFiles] = useState<ExtFile[]>([]);

    useEffect(() => {
        setLoading(true);

        ws.getSalaryReceipts(token!)
            .then((files: ExtFile[]) => {
                setFiles(reorderAndFilterFiles(files));
            })
            .catch((err: any) => {
                setError({
                  hasError: true,
                  title: 'File Error',
                  message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const handleDownload = (filename: string | undefined) => {

            if(filename) {
                setLoading(true);

                ws.downloadSalaryReceipt(filename, token!)
                    .then((href: string) => {
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href); 
                    })
                    .catch((err: any) => {
                        setError({
                            hasError: true,
                            title: 'Salary Receipt Error',
                            message: err.message
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            } else {
                setError({
                    hasError: true,
                    title: 'Salary Receipt',
                    message: 'No download URL'
                });
            }
    };
    
    // return <Grid container spacing={2}>
    //         {
    //             files.map((file: ExtFile, idx: number) => {
    //                 return <Grid key={idx} item xs={12} sm={12} md={6} lg={4}>
    //                             <FileCard 
    //                                 {...file }
    //                                 onDownload={() => handleDownload(undefined, file.downloadUrl)}/>
    //                     </Grid>
    //             })
    //         }
    //     </Grid>

    return <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nomina</TableCell>
                            <TableCell align="right">Fecha Creatión</TableCell>
                            <TableCell align="right">Descargar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            files.map((file: ExtFile, idx: number) => {
                                return <TableRow key={idx}>
                                    <TableCell>{file.name}</TableCell>
                                    <TableCell align="right">{new Date((file.extraData! as any).ctime).toLocaleDateString()}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleDownload(file.name)}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
}

export default SalaryReceipt;