import { useState, createContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IError, initialState, IAlertError } from './models/errorMessageModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    redColored: {
        color: theme.palette.error.main
    }
}));

const AlertError: React.FC<IAlertError> = ({ error, handleClose }) => {

    const { classes } = useStyles();

    return <Dialog
        open={error.hasError}
        onClose={() => handleClose(error)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className={classes.redColored}>
            {error.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {error.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(error)} className={classes.redColored} autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>
};

export const ErrorMessageContext = createContext<{
    error: IError;
    setError: (a: IError) => void;
}>({
    error: initialState,
    setError: (a: IError) => { }
});

export interface IErrorMessageProvider {
    children: any, 
    logout: () => void
}

const ErrorMessageProviderr: React.FC<IErrorMessageProvider> = ({ children, logout }) => {

    const [error, setError] = useState<IError>(initialState);

    const handleClose = (error: IError) => {
        if (error.message.startsWith("Inv_token")) {
            logout();
        }
        setError({
            ...error,
            hasError: false
        })
    }

    return (
        <ErrorMessageContext.Provider value={{ error, setError }}>
            { children }
            <AlertError
                error={error}
                handleClose={handleClose} />
        </ErrorMessageContext.Provider>
    )
}

export default ErrorMessageProviderr;