import { Paper, TableContainer, Table, TableHead, TableRow, TableBody }  from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { IFollowUp } from '../../../ws/models/followUpModels';

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: '2px 4px',
        margin: '0 0 10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 40px)'
    },
    iconBtn: {
        padding: '10px'
    },
    divider: {
        height: 28,
        margin: 0.5
    },
    inputBase: {
        margin: 1,
        paddingLeft: '20px',
        flex: 1
    },
    table: {
        width: '100%'
    },
    tablerow: {
        '&:last-child td, &:last-child th': {
            border: '0'
        }
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const FixedWithTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      width: '22%'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface IFollowUpBody {
    data: IFollowUp[]
}

const FollowUpBody: React.FC<IFollowUpBody> = (props) => {

    const { classes } = useStyles();

    return <TableContainer component={Paper}>
                <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Nr Seguimiento</StyledTableCell>
                        <StyledTableCell>Nr Ope</StyledTableCell>
                        <StyledTableCell>Ope STD</StyledTableCell>
                        <StyledTableCell>Tiempo</StyledTableCell>
                        <FixedWithTableCell>Descritivo</FixedWithTableCell>
                        <StyledTableCell>Nr OF</StyledTableCell>
                        <StyledTableCell>Centro</StyledTableCell>
                        <StyledTableCell>Quantidade</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((fu: IFollowUp, idx: number) => (
                        <StyledTableRow
                            key={idx}
                            >
                            <StyledTableCell>{ fu.MFGTRKNUM }</StyledTableCell>
                            <StyledTableCell>{ fu.OPENUM }</StyledTableCell>
                            <StyledTableCell>{ fu.STDOPENUM }</StyledTableCell>
                            <StyledTableCell>{ fu.XOPETIM }</StyledTableCell>
                            <FixedWithTableCell>{ fu.XDESCRIPOPER }</FixedWithTableCell>
                            <StyledTableCell>{ fu.MFGNUM }</StyledTableCell>
                            <StyledTableCell>{ fu.CPLWST }</StyledTableCell>
                            <StyledTableCell>{ fu.CPLQTY }</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
}

export default FollowUpBody;