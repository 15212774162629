import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { IAbsent } from "../../../ws/models/absentsModels";

const useStyles = makeStyles()((theme) => ({
    lefttable: {
        padding: '2px 4px',
        margin: '0 0 40px 0',
    },
    box: {
        width: '100%',
    },
    sectionTitle: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: '20px'
    },
    mediumTable: {
        width: '50%'
    },
    biggerTypo: {
        fontWeight: 'bolder',
        fontSize: '1.1rem',
        marginRight: '5px'
    },
    normalTypo: {
        marginRight: '5px'
    },
    title: {
        fontWeight: 'bold'
    }
}));

interface IAbsentTotals {
    absent: IAbsent
}

const AbsentTotals: React.FC<IAbsentTotals> = ({ absent }) => {
    const { classes } = useStyles();

    return <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={12}>
                <Box className={classes.box}>
                    {
                        absent ? 
                        <>
                            <TableContainer className={classes.mediumTable}>
                                <Table sx={{ minWidth: 180  }}  component={Paper} size='small'>
                                    <TableHead>
                                        <TableRow>                                                            
                                            <TableCell align='center' className={classes.title}>Vacaciones { absent.year }</TableCell>
                                            <TableCell align='center' className={classes.title}></TableCell>
                                            <TableCell align='center' className={classes.title}>Vacaciones { absent.year - 1 }</TableCell>
                                            <TableCell align='center' className={classes.title}></TableCell>
                                            <TableCell align='center' className={classes.title}>Horas Consumidas</TableCell>
                                            <TableCell align='center' className={classes.title}></TableCell>
                                            <TableCell align='center' className={classes.title}>Horas Disponibles</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='center'>{ absent.vacationsCurYear }</TableCell>
                                            <TableCell align='center'>+</TableCell>
                                            <TableCell align='center'>{ absent.vacationsLastYear }</TableCell>
                                            <TableCell align='center'>-</TableCell>
                                            <TableCell align='center'>{ absent.vacationsTotal }</TableCell>
                                            <TableCell align='center'>=</TableCell>
                                            <TableCell align='center'>{ absent.vacationsAvailable }</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br/>
                            <TableContainer className={classes.mediumTable}>
                                <Table sx={{ minWidth: 180 }}  component={Paper} size='small'>
                                    <TableHead>
                                        <TableRow>                                                            
                                            <TableCell align='center' className={classes.title}>Vacaciones (Hrs)</TableCell>
                                            <TableCell align='center' className={classes.title}>Asuntos Propios (Hrs)</TableCell>
                                            <TableCell align='center' className={classes.title}>Licencias (Hrs)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='center'>{ absent.vacationsTotal - absent.totalAbsent - absent.totalLicense }</TableCell>
                                            <TableCell align='center'>{ absent.totalAbsent }</TableCell>
                                            <TableCell align='center'>{ absent.totalLicense }</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </> : null
                    }
                </Box>
            </Grid>
        </Grid>
}

export default AbsentTotals;