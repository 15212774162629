export const initialState: IYesNo = {
    hasYesNo: false,
    title: '',
    content: '',
    handleNo: () => { },
    handleYes: () => { }
}

export interface IAlertYesNo {
    yesNo: IYesNo,
    handleClose: () => void
}

export interface IYesNo {
    hasYesNo: boolean,
    title: string,
    content: string,
    handleNo: () => void,
    handleYes: () => void
}