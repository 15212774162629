import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { useContext } from 'react';

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
    const { state: { token } } = useContext(AuthContext);
    const location = useLocation();

    if (!token) {
        return <Navigate to='/home' replace state={{ from: location }} />
    }

    return children;
};

export default ProtectedRoute;
