import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import AuthProvider from './AuthProvider';
import ConfirmMessageProvider from './ConfirmMessageProvider';
import ErrorMessageProvider from './ErrorMessageProvider';
import InfoProvider from './InfoProvider';
import LoadingProvider from './LoadingProvider';
import StaticOptionsProvider from './StaticOptionsProvider';
import YesNoProvider from './YesNoProvider';
import { AuthContext } from './AuthProvider';
import { LOGOUT } from './models/authModel';

const useStyles = makeStyles()((theme) => ({
    allApp: {
        backgroundColor: theme.palette.primary.dark,
        height: '100vh',
        position: 'relative',
        fontSize: '1rem',
    },
}));

const Providers: React.FC<{ children: any }> = ({ children }) => {

    const { classes } = useStyles();
    const { state, dispatch } = useContext(AuthContext);

    return <div className={classes.allApp}>
            <AuthProvider>
                <ConfirmMessageProvider>
                    <ErrorMessageProvider logout={() => dispatch({ type: LOGOUT, payload: state })}>
                        <InfoProvider>
                            <LoadingProvider>
                                <StaticOptionsProvider>
                                    <YesNoProvider>
                                        { children }
                                </YesNoProvider>
                            </StaticOptionsProvider>
                        </LoadingProvider>
                    </InfoProvider>
                </ErrorMessageProvider>
            </ConfirmMessageProvider>
        </AuthProvider>
    </div>
}

export default Providers;