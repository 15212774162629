import React from 'react';
import { Link } from 'react-router-dom';

interface IButtonMailTo {
    mailto: string;
    label: string;
}

const ButtonMailTo: React.FC<IButtonMailTo> = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e: any) => {
                window.location.href = mailto;
                e.preventDefault();
            }}>
            {label}
        </Link>
    );
}

export default ButtonMailTo;