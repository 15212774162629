import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody  }  from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IInOutCheck } from '../../../ws/models/inoutcheckModels';

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: '2px 4px',
        margin: '0 0 10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 40px)'
    },
    iconBtn: {
        padding: '10px'
    },
    divider: {
        height: 28,
        margin: 0.5
    },
    inputBase: {
        margin: 1,
        paddingLeft: '20px',
        flex: 1
    },
    table: {
        width: '100%'
    },
    tablerow: {
        '&:last-child td, &:last-child th': {
            border: '0'
        }
    }
}));

interface IAccordionBody {
    data: IInOutCheck[]
}

const AccordionBody: React.FC<IAccordionBody> = (props) => {

    const { classes } = useStyles();

    return <TableContainer component={Paper}>
                <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Hora</TableCell>
                        <TableCell align="right">IdTerminal</TableCell>
                        <TableCell align="right">Estado</TableCell>
                        <TableCell align="right">NIF</TableCell>
                        <TableCell align="right">ID</TableCell>
                        <TableCell align="right">Empleado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((check: IInOutCheck, idx: number) => (
                        <TableRow
                            key={idx}
                            className={classes.tablerow}>
                            <TableCell component="th" scope="row">{ check.time }</TableCell>
                            <TableCell align="right">{ check.terminalId }</TableCell>
                            <TableCell align="right">{ check.state }</TableCell>
                            <TableCell align="right">{ check.nif }</TableCell>
                            <TableCell align="right">{ check.idEmpleado }</TableCell>
                            <TableCell align="right">{ check.name } { check.secondName }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
}

export default AccordionBody;