import { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { ExtFile, FileCard } from '@files-ui/react';
import FileRepoUpload from './filerepo/FileRepoUpload';
import { makeStyles } from 'tss-react/mui';
import { ws } from '../../ws/call';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';
import { InfoMessageContext } from '../../context/InfoProvider';
import { YesNoContext } from '../../context/YesNoProvider';
import { AddCircle } from '@mui/icons-material';
import FadeModal from '../../utils/FadeModal';
import { IYesNo } from '../../context/models/yesNoModel';

const useStyles = makeStyles()((theme) => ({
    paper: {
        width: '100%'
    },
    downloadButton: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.primary.main,
		float: 'right',
		marginBottom: '10px',
    },
    filecard: {
        width: '100% !important',
        '> .files-ui-file-card-main-layer-container': {
            width: '100% !important',
        }
    }
}));

const FileRepository: React.FC<{}> = () => { 
    const { classes } = useStyles();
    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);
    const { setInfo } = useContext(InfoMessageContext);
    const { setYesNo } = useContext(YesNoContext);

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [update, setUpdate] = useState<number>(0);
    const [files, setFiles] = useState<ExtFile[]>([]);

    useEffect(() => {
        setLoading(true);

        ws.getUserFiles(token!)
            .then((files: ExtFile[]) => {
                setFiles(files);
            })
            .catch((err: any) => {
                setError({
                  hasError: true,
                  title: 'File Error',
                  message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }, [update]);

    const handleDownload = (
        fileId: string | number | undefined,
        filename?: string | undefined
       ) => {

            if(filename) {
                setLoading(true);

                ws.downloadFile(filename, token!)
                    .then((href: string) => {
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href); 
                    })
                    .catch((err: any) => {
                        setError({
                        hasError: true,
                        title: 'File Error',
                        message: err.message
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            } else {
                setError({
                    hasError: true,
                    title: 'File Error',
                    message: 'No download URL'
                });
            }
    };

    const handleDelete = (filename: string | undefined) => {

        if(filename) {
            setYesNo({
                hasYesNo: true,
                title: 'Warning!',
                content: `Delete ${filename}?          `,
                handleNo: () => {},
                handleYes: () => deleteFile(filename)
            });

        } else {
            setError({
                hasError: true,
                title: 'File Error',
                message: 'No download URL'
            });
        }
    }

    const deleteFile = (filename: string) => {
        
        setLoading(true);

        ws.deleteFile(filename, token!)
            .then((deleted: boolean) => {
                if(deleted) {
                    setInfo({
                        hasInfo: true,
                        message: `${filename} deleted!`,
                        title: 'File'
                    })
                } else {
                    setError({
                        hasError: true,
                        title: 'File Error',
                        message: `Couldn't delete file`
                    });
                }

                setUpdate(update + 1);
            })
            .catch((err: any) => {
                setError({
                    hasError: true,
                    title: 'File Error',
                    message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCloseAddFile = () => {
        setOpenAdd(false);
        setUpdate(update + 1);
    }
    
    return <>
        <Button
            className={classes.downloadButton}
            variant="contained"
            onClick={() => setOpenAdd(true)}
            startIcon={<AddCircle />}>
            Add File
        </Button>
        <br />
        <Grid container spacing={2}>
            {
                files.map((file: ExtFile, idx: number) => {
                    return <Grid key={idx} item xs={12} sm={12} md={6} lg={4}>
                                <FileCard 
                                    className={classes.filecard}
                                    {...file}
                                    onDownload={() => handleDownload(undefined, file.name)}
                                    onDelete={() => handleDelete(file.name)}/>
                        </Grid>
                })
            }
        </Grid>
        <FadeModal open={openAdd} handleClose={handleCloseAddFile}>
            <FileRepoUpload token={token!} handleClose={handleCloseAddFile}/>
        </FadeModal>
    </>
}

export default FileRepository;