import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { ErrorMessageContext } from "../../context/ErrorMessageProvider";
import { LoadingContext } from "../../context/LoadingProvider";
import { ws } from "../../ws/call";
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, InputAdornment, Button } from "@mui/material";
import { ExpandMore, FilterAlt as FilterAltIcon } from '@mui/icons-material';
import AccordionBody from "./inoutcheck/AccordionBody";
import { makeStyles } from 'tss-react/mui';
import { IDayCheck, IInOutCheck } from "../../ws/models/inoutcheckModels";
import InOutFilters from "./inoutcheck/InOutFilters";
import { getStringDate } from "../../helpers/date";

const useStyles = makeStyles()((theme) => ({
    typography: {
        alignSelf: 'center',
    },
}));

const InOutCheck: React.FC<{}> = () => {

    const { classes } = useStyles();

    const { state: { info: userInfo, token } } = useContext(AuthContext);
    const { setError } = useContext(ErrorMessageContext);
    const { setLoading } = useContext(LoadingContext);

    const [inOutCheck, setInOutCheck] = useState<IDayCheck[]>([]);
    const [filteredInOutCheck, setFilteredInOutCheck] = useState<IDayCheck[]>([]);

    useEffect(() => {
        setLoading(true);

        ws.getInOutCheckHistoryV2(token!)
            .then((res: IDayCheck[]) => {
                setInOutCheck(res);
                setFilteredInOutCheck(res);
            })
            .catch((err: any) => {
                setError({
                  hasError: true,
                  title: 'InOut Error',
                  message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const filter = (nifFilter: string, nameFilter: string, idEmpleadoFilter: string) => {

        setLoading(true);

        const result: IDayCheck[] = [];

        inOutCheck.forEach((io: IDayCheck) => {
            const lines: IInOutCheck[] = io.list.filter((line: IInOutCheck) => 
                (!nifFilter || (line.nif || '').toUpperCase().includes(nifFilter.toUpperCase())) && 
                (!nameFilter || (line.name || '').toUpperCase().includes(nameFilter.toUpperCase())
                             || (line.secondName || '').toUpperCase().includes(nameFilter.toUpperCase())) && 
                (!idEmpleadoFilter || (line.idEmpleado || '').toUpperCase().includes(idEmpleadoFilter.toUpperCase())));

            if(lines.length) {
                result.push({ date: io.date, list: lines });
            }
        });

        setFilteredInOutCheck(result); 
        setLoading(false);
    }

    return  <>
                <InOutFilters filter={filter}/>
                {
                    filteredInOutCheck.map((day: IDayCheck, idx: number) => {
                        return <Accordion key={idx}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}>
                                        <Typography className={classes.typography} variant='h6' color='primary'>
                                           { getStringDate(day.list[0].date) }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AccordionBody data={ day.list } />
                                    </AccordionDetails>
                                </Accordion>
                    })
                }
            </>
}

export default InOutCheck;