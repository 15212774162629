import { useState, useContext, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, InputAdornment, Typography } from '@mui/material';
import { Person, Lock } from '@mui/icons-material';

import { ErrorMessageContext } from '../context/ErrorMessageProvider';
import { LoadingContext } from '../context/LoadingProvider';
import { DEMO, LOGIN } from '../context/models/authModel';
import { AuthContext } from '../context/AuthProvider';
import { ws } from '../ws/call';
import { makeStyles } from 'tss-react/mui';
import { IUser } from '../ws/models/userModels';
import { ICustomer } from '../ws/models/customerModels';

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginBottom: theme.spacing(0),
      fontSize: '1.2rem'
    }
  },
  demoTitle: {
    marginBottom: theme.spacing(3),
    color: theme.palette.error.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '& .MuiFormControl-marginNormal': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        margin: '8px 0'
      }
    },
    '& input': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        padding: '12px 2px'
      }
    },
    '& button': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        margin: '8px 0 4px'
      }
    }
  },
  inputTextFld: {
    backgroundColor: 'white',
    '& input': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        padding: '12px 2px'
      }
    }
  },
  demoInputTextFld: {
    backgroundColor: 'white',
    '&. Mui-focused fieldset': {
      borderColor: theme.palette.error.main
    }
  },
  primaryIcon: {
    color: theme.palette.primary.main
  },
  demoIcon: {
    color: theme.palette.error.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  demoSubmit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
    backgroundColor: theme.palette.error.main
  }
}));

interface ISignIn {
    option: string,
    demoMode: boolean,
    setDemoMode: (a: boolean) => void
}

const SignIn: React.FC<ISignIn> = ({ option, demoMode, setDemoMode }) => {

  const { classes } = useStyles();

  const navigate = useNavigate();
  const { dispatch: userDispatch } = useContext(AuthContext);
  const { setError } = useContext(ErrorMessageContext);
  const { setLoading } = useContext(LoadingContext);

  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  useEffect(() => {
    if(option && option.toUpperCase() === DEMO) {
      setDemoMode(true);
      setUsername(DEMO);
    }
  }, [option]);

  const handleUsername = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();

    let { value } = ev.target;

    if(value.toUpperCase() === DEMO) {
      setDemoMode(true);
    } else {
      setDemoMode(false);
    }

    setUsername(value);
    if(value)
      setUsernameError('');
  }

  const handlePassword = (ev: any) => {
    ev.preventDefault();

    let { value } = ev.target;

    setPassword(value);
    if(value)
      setPasswordError('');
  }

  const signin = (ev: any) => {
    ev.preventDefault();

    setLoading(true);

    if(!demoMode && !username) {
      setUsernameError('Missing');
      setLoading(false);
      return;
    }

    if(!demoMode && !password) {
      setPasswordError('Missing');
      setLoading(false);
      return;
    }

    if(!demoMode) {
      //try data from X3
      ws.notFaking();
    } else {
      ws.faking();
    }

    ws.login(!demoMode ? username : DEMO, password)
    .then((user: IUser) => {

      if(!user.data.customerId) {
        throw new Error('No customer selected! Please talk to F5IT for further information');
      }

      userDispatch({
        type: !demoMode ? LOGIN : DEMO,
        payload: {
          isAuthenticated: true,
          info: user.data,
          token: user.token
        }
      });

      navigate('/main');
    })
    .catch((err: any) => {
      setError({
        hasError: true,
        title: 'Main Login Error',
        message: err.message
      });
    })
    .finally(() => {
      setLoading(false);
    })
  }

  return <>
          <Typography variant='h5' color='primary' className={!demoMode ? classes.title : classes.demoTitle}>
            { !demoMode ? 'Log In - Customer Account' : 'Enter Demo Mode' }
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={!demoMode ? classes.inputTextFld : classes.demoInputTextFld}
              variant='outlined'
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={ !demoMode ? username : DEMO }
              autoFocus
              disabled={demoMode}
              error={usernameError ? true : false}
              helperText={usernameError}
              onChange={handleUsername}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Person className={!demoMode ? classes.primaryIcon : classes.demoIcon} />
                  </InputAdornment>
                ),
              }}/>
            {
              !demoMode ? 
                <TextField
                  className={classes.inputTextFld}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  disabled={demoMode}
                  error={passwordError ? true : false}
                  helperText={passwordError}
                  onChange={handlePassword}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Lock className={classes.primaryIcon} /> 
                      </InputAdornment>
                    )
                }}/> : null
            }
            <Button
              type='submit'
              fullWidth
              variant='contained'
              className={!demoMode ? classes.submit : classes.demoSubmit}
              onClick={signin}>
              { !demoMode ? 'Sign In' : 'Demo' }
            </Button>
          </form>
        </>
}

export default SignIn;