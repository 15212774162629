export const initialState: IInfo = {
    hasInfo: false,
    title: '',
    message: ''
}

export interface IAlertInfo {
    info: IInfo,
    handleClose: () => void
}

export interface IInfo {
    hasInfo: boolean,
    title: string,
    message: string
}