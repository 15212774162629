import { useState, createContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IYesNo, IAlertYesNo, initialState } from './models/yesNoModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    confirmationTitle: {
        color: theme.palette.primary.main
    },
    disagree: {
        color: theme.palette.text.disabled
    },
    errorTitle: {
        color: theme.palette.error.main
    },
    warningTitle: {
        color: theme.palette.info.main
    }
}));


export const YesNoContext = createContext<{
    yesNo: IYesNo;
    setYesNo: (a: IYesNo) => void;
}>({
    yesNo: initialState,
    setYesNo: (a: IYesNo) => { }
});

const AlertYesNo: React.FC<IAlertYesNo> = ({ yesNo, handleClose }) => {

    const { classes } = useStyles();

    return <Dialog
                open={yesNo.hasYesNo}
                onClose={ handleClose }
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' className={classes.confirmationTitle}>
                    { yesNo.title }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                    { yesNo.content }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={ handleClose } 
                        className={classes.disagree}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={ () => { handleClose(); yesNo.handleNo() }}
                        className={classes.warningTitle}>
                        No
                    </Button>
                    <Button
                        onClick={ () => { handleClose(); yesNo.handleYes() }}
                        color='primary'
                        autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
}

const YesNoProvider: React.FC<{ children: any }> = ({ children }) => {

    const [yesNo, setYesNo] = useState<IYesNo>(initialState);

    const handleClose = () => {
        setYesNo(initialState);
    }

    return <YesNoContext.Provider value={{ yesNo, setYesNo }}>
                { children }
                <AlertYesNo
                    yesNo={yesNo}
                    handleClose={handleClose} />
            </YesNoContext.Provider>
}

export default YesNoProvider;