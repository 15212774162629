import { useContext, useState } from 'react';
import { Dialog, DialogTitle, DialogContent , ToggleButtonGroup, DialogActions, Button, ToggleButton, TextField, Grid, Typography, FormControl, Slider, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { TileArgs, View } from 'react-calendar/dist/cjs/shared/types';
import { IAbentMotive, IAbsentLine, IAggrAbsentLines, INewAbsentLine, IUnavailableDay } from '../../../ws/models/absentsModels';
import { ErrorMessageContext } from '../../../context/ErrorMessageProvider';
import { x3cast } from '../../../ws/x3cast';
import { isWeekend } from '../../../helpers/date';


const useStyles = makeStyles()((theme) => ({
    box: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        width: '50%',
        height: '100%',
        border:  '1px solid #000',
        borderRadius: '10px',
        boxShadow: '10px 10px 5px',
        padding: theme.spacing(2),
    },
    dialog: {
        width: '100%',
        height: '100%',
    },
    typography: {
        marginTop: theme.spacing(2)
    },
    confirmationTitle: {
        color: theme.palette.primary.main
    },
    disagree: {
        color: theme.palette.text.disabled
    },
    errorTitle: {
        color: theme.palette.error.main
    },
    warningTitle: {
        color: theme.palette.info.main
    },
    griditem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    optionLbl: {
        display: 'block'
    },
    vacationsDay: {
        backgroundColor: '#E3DE36 !important'
    },
    absentDay: {
        backgroundColor: '#E35436 !important'
    },
    holyDay: {
        backgroundColor: '#3685E3 !important'
    },
}));

interface IAddAbsent {
    absentLines: IAbsentLine[],
    unavailableDays: IUnavailableDay[],
    absentMotives: IAbentMotive[],
    open: boolean
    setOpenAdd: (a: boolean) => void,
    add: (newAbsentLines: INewAbsentLine[]) => void
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const SINGLE = 's', MULTI = 'm';

const AddAbsent: React.FC<IAddAbsent> = (props) => {

    const { classes } = useStyles();
    const { setError } = useContext(ErrorMessageContext);

    const [dateRangeType, setDateRangeType] = useState<string>(SINGLE);
    
    const [dateRange, setDateRange] = useState<Value>(new Date());
    const [absentType, setAbsentType] = useState<string>('1');
    const [absentMotive, setAbsentMotive] = useState<string>('VC');
    const [absentDuration, setAbsentDuration] = useState<number>(8);
    const [maxAbsentDuration, setMaxAbsentDuration] = useState<number>(8);

    const handleAbsentType = (
        event: React.MouseEvent<HTMLElement>,
        newAbsentType: string | null,
    ) => {
        if(newAbsentType === '1') {
            setAbsentMotive('VC');
        }
        setAbsentType(newAbsentType!);
        calculateMaxDayDuration(dateRangeType, newAbsentType!, dateRange);
    };

    const handleAbsentMotive = (ev: any) => {
        setAbsentMotive(ev.target!.value!);
    }

    const handleDateRangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDateRangeType = (event.target as HTMLInputElement).value;
        if(dateRangeType === MULTI && newDateRangeType === SINGLE && Array.isArray(dateRange)) {
            setDateRange(dateRange[0])
        }
        setDateRangeType(newDateRangeType);
        calculateMaxDayDuration(newDateRangeType, absentType, dateRange);
    };

    const handleDuration = (ev: any) => {
        setAbsentDuration(Number(ev.target!.value))
    }

    const sliderValueText = (v: number) => `${v}`;

    const calculateMaxDayDuration = (dateRangeType: string, absentType: string, dateRange: Value) => {

        if(dateRangeType === MULTI || absentType === '1' || Array.isArray(dateRange) || !dateRange) {
            setMaxAbsentDuration(8);
            setAbsentDuration(8);
        } else {
            const max = 8 - (props.absentLines.find((al: IAbsentLine) => al.date.toDateString() === dateRange.toDateString())?.duration ?? 0);
            setMaxAbsentDuration(max);
            setAbsentDuration(max < absentDuration ? max : absentDuration);
        }
    }

    const handleClassName = (tile: TileArgs): string => {
        
        if(tile.view === 'month') {
            // if(props.unavailableDays.some((ud: IUnavailableDay) => ud.day.toDateString() === tile.date.toDateString())) {
            //     return classes.holyDay;
            // }

            const line: IAbsentLine | undefined = props.absentLines.find((al: IAbsentLine) => al.date.toDateString() === tile.date.toDateString());
            if(line) {
                return line.type === 1 ? classes.vacationsDay : classes.absentDay;
            }
        }

        return '';
    }

    const handleDisabledDays = (tile: TileArgs): boolean => {
        if(tile.view === 'month') {
            // return props.unavailableDays.some((ud: IUnavailableDay) => ud.day.toDateString() === tile.date.toDateString()) ||
            return props.absentLines.some((al: IAbsentLine) => al.date.toDateString() === tile.date.toDateString());
        } else { 
            return false;
        }
    }

    const handleDateChange = (value: Value, event: any) => {
        setDateRange(value);
        calculateMaxDayDuration(dateRangeType, absentType, value);
    }

    const handleCancel = () => {
        reset();
        props.setOpenAdd(false);
    }

    const handleYes = () => {
        if((absentType === '2' || absentType === '3') && absentMotive === 'VC') {
            setError({
                hasError: true,
                title: 'Add Absent Line Error',
                message: 'Must choose a Motive'
              });
        }

        if(dateRangeType === MULTI && Array.isArray(dateRange)) {
            let newLines: INewAbsentLine[] = [];

            let [start, end] = dateRange as [Date, Date];
            let loop = start;
            while(loop <= end) {
                
                if(!isWeekend(loop) 
                    // && !props.unavailableDays.some((ud: IUnavailableDay) => ud.day.toDateString() === loop.toDateString())
                    && !props.absentLines.find((al: IAbsentLine) => al.date.toDateString() === loop.toDateString())) {
                    
                    newLines.push({
                        date: x3cast.dateToX3Date(loop),
                        duration: absentDuration,
                        motive: absentMotive,
                        type: Number(absentType)
                    });
                }

                let newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
             }

             props.add(newLines);

        } else if(dateRangeType === SINGLE && !Array.isArray(dateRange)) {
            props.add([{
                date: x3cast.dateToX3Date(dateRange as Date),
                duration: absentDuration,
                motive: absentMotive,
                type: Number(absentType)
            }]);
        } else {
            setError({
                hasError: true,
                title: 'Dates Error',
                message: 'Error on choosing dates'
              });
        }
        reset();
    }

    const reset = () => {
        setDateRangeType(SINGLE);
        setDateRange(new Date());
        setAbsentType('1');
        setAbsentMotive('VC');
        setAbsentDuration(8);
        setMaxAbsentDuration(8);
    }

     return   <Dialog
                open={props.open}
                className={classes.dialog}
                onClose={ () => { props.setOpenAdd(false) }}
                aria-labelledby='alert-dialog-title'>
                <DialogTitle id='alert-dialog-title'
                    className={classes.confirmationTitle}>
                    { 'Añadir Asunto Proprio'.toUpperCase() }
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.griditem}>
                            <Typography variant='button' className={classes.optionLbl}>Tipo de Asunto</Typography>
                            <ToggleButtonGroup
                                value={absentType}
                                color='primary'
                                exclusive
                                onChange={handleAbsentType}>
                                <ToggleButton value='1'>Vacación</ToggleButton>
                                <ToggleButton value='2'>Asuntos Propios</ToggleButton>
                                <ToggleButton value='3'>Licencia</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {
                            absentType === '2' || absentType === '3' ?
                            <Grid item xs={12} className={classes.griditem}>
                                <Typography variant='button' className={classes.optionLbl}>Motivo</Typography>
                                <Select
                                    value={absentMotive}
                                    label='Motivo'
                                    onChange={handleAbsentMotive}>
                                    {
                                        props.absentMotives!.filter((am: IAbentMotive) => {
                                            return am.group === absentType
                                        }).map((am: IAbentMotive, idx: number) => (
                                            <MenuItem key={idx} value={am.code}>{am.description}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid> : null
                        }
                        <Grid item xs={12} className={classes.griditem}>
                        <FormControl>
                        <Typography variant='button' className={classes.optionLbl}>Tipo de Fecha</Typography>
                            <RadioGroup
                                row
                                value={dateRangeType}
                                aria-labelledby='tipo-fecha'
                                onChange={handleDateRangeType}>
                                <FormControlLabel value={SINGLE} control={<Radio />} label='Un día' />
                                <FormControlLabel value={MULTI} control={<Radio />} label='Múltiplos Días' />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.griditem}>
                            <Typography variant='button' className={classes.optionLbl}>Fecha</Typography>
                            <Calendar
                                selectRange={dateRangeType !== SINGLE}
                                returnValue={dateRangeType === SINGLE ? 'start' : 'range'}
                                onChange={handleDateChange}
                                tileClassName={handleClassName}
                                minDetail='year'
                                locale='es-ES'
                                tileDisabled={handleDisabledDays} />
                        </Grid>
                        <Grid item xs={12} className={classes.griditem}>
                            <Typography variant='button' className={classes.optionLbl}>Horas ({ absentDuration })</Typography>
                            <Slider
                                aria-label="Horas"
                                value={absentDuration}
                                onChange={handleDuration}
                                getAriaValueText={sliderValueText}
                                step={1}
                                marks
                                disabled={dateRangeType !== SINGLE}
                                min={1}
                                max={maxAbsentDuration}
                                valueLabelDisplay="auto" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCancel} 
                        className={classes.disagree}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleYes}
                        color='primary'
                        autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
    }

export default AddAbsent;
