import { Modal, Backdrop, Fade, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  modal: {
    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
        maxWidth: '720px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60%',
        margin: 'auto',
        // overflow:'scroll'
    },
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
        maxWidth: '720px',
        width: '90%',
        position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block',
        margin: 'auto'
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        maxWidth: '400px',
        width: '90%',
        position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block',
        margin: 'auto'
    },
    '.MuiModal-backdrop': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: '-2'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
    borderRadius: 10
  }
}));

interface IFadeModal {
    children: any,
    open: boolean,
    handleClose: () => void
}

const FadeModal: React.FC<IFadeModal> = (props) => {
  const { classes } = useStyles();

  return <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
          <Fade in={props.open}>
            <Box className={classes.paper}>
                { props.children }
            </Box>
          </Fade>
        </Modal>
}

export default FadeModal;