import { useEffect, useReducer, createContext } from 'react';
import { userReduce, initialState, INITIAL_STATE, LOCAL_STORAGE_KEY, IAuthContext } from './models/authModel';

export const AuthContext = createContext<{
    state: IAuthContext;
    dispatch: React.Dispatch<{ type: string, payload: IAuthContext }>;
}>({
    state: INITIAL_STATE,
    dispatch: () => null
});

const AuthProvider: React.FC<{ children: any }> = ({ children }) => {

    const [state, dispatch] = useReducer(userReduce, initialState());

    useEffect(() => {
        if(state !== INITIAL_STATE) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
        }
    }, [state]);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
