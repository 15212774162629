import { useState } from 'react';
import { Grid, MenuItem, Select } from '@mui/material';
import { ExtFile, FileCard } from '@files-ui/react';
import { makeStyles } from 'tss-react/mui';
import { ws } from '../../ws/call';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { ErrorMessageContext } from '../../context/ErrorMessageProvider';
import { LoadingContext } from '../../context/LoadingProvider';


const useStyles = makeStyles()((theme) => ({
    select: {
        marginTop: '20px',
        marginBottom: '50px'
    },
    filecard: {
        width: '100% !important',
        '> .files-ui-file-card-main-layer-container': {
            width: '100% !important',
        }
    }
}));


const IntroducirPartes: React.FC<{}> = () => { 
    const { classes } = useStyles();


    return <iframe src={'http://192.168.1.50:8083/partes-trabajo/'} title="Partes de Trabajo" width="100%" height="1200"></iframe>
}

export default IntroducirPartes;