const x3datergx = /^([1|2]{1}\d{3})(0\d|1[0-2])(0[1-9]|[1|2]\d|3[0|1])$/;
const datergx = /^(0[1-9]|[1|2]\d|3[0|1])\/(0\d|1[0-2])\/([1|2]{1}\d{3})$/;

export interface IX3Cast {
    x3dateToString: (a: string) => string,
    x3dateToDate: (a: string) => string | Date,
    x3dateToOnlyDate: (a:string) => Date,
    x3getYear: (a: string) => string,
    x3getMonth: (a: string) => string,
    x3getStrMonth: (a: number) => string,
    x3ShortToLongMonth: (short: string) => string,
    x3dateToJSDate: (x3date: string) => Date,
    dateToX3Date: (date: Date) => string,
    dateMinusMonth: (minusMonth: number) => Date, 
    getMenuFromDescription: (desc: string, menu: number) => any
}

export const x3cast: IX3Cast = {
    x3dateToString: (x3date: string): string => {
        if (!x3date || x3date === '00/00/0000') return '-';

        if (datergx.test(x3date)) {
            let match = x3date.match(datergx);
            return `${match![1]}/${getMonthDate(match![2])}/${match![3]}`;
        }

        if (!x3datergx.test(x3date)) throw new Error(`${x3date} is not a X3 Date Format`);

        let match = x3date.match(x3datergx);
        return `${match![3]}/${getMonthDate(match![2])}/${match![1]}`;
    },

    x3dateToDate: (x3date: string): string | Date => {
        if (!x3date || x3date === '00/00/0000') return '-';

        if (datergx.test(x3date)) return x3date;

        if (!x3datergx.test(x3date)) throw new Error(`${x3date} is not a X3 Date Format`);

        let match = x3date.match(x3datergx);
        return new Date(Number(match![1]), parseInt(match![2]) - 1, Number(match![3]));
    },

    x3dateToOnlyDate: (x3date: string): Date => {
        if (!x3date || x3date === '00/00/0000') return new Date(1970,0,1);

        if (!x3datergx.test(x3date)) throw new Error(`${x3date} is not a X3 Date Format`);

        let match = x3date.match(x3datergx);
        return new Date(Number(match![1]), parseInt(match![2]) - 1, Number(match![3]));
    },

    x3getYear: (x3date: string): string => {
        return x3date.substring(6);
    },

    x3getMonth: (x3date: string): string => {
        return x3date.substring(3, 5);
    },

    x3getStrMonth: (month: number): string => {
        switch (month) {
            case 1:
                return 'January';
            case 2:
                return 'February';
            case 3:
                return 'March';
            case 4:
                return 'April';
            case 5:
                return 'May';
            case 6:
                return 'June';
            case 7:
                return 'July';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'October';
            case 11:
                return 'November';
            case 12:
                return 'December';
            default:
                return 'NA';
        }
    },

    x3ShortToLongMonth: (short: string): string => {
        switch (short) {
            case 'Jan':
                return 'January';
            case 'Feb':
                return 'February';
            case 'Mar':
                return 'March';
            case 'Apr':
                return 'April';
            case 'May':
                return 'May';
            case 'Jun':
                return 'June';
            case 'Jul':
                return 'July';
            case 'Aug':
                return 'August';
            case 'Sep':
                return 'September';
            case 'Out':
                return 'October';
            case 'Nov':
                return 'November';
            case 'Dec':
                return 'December';
            default:
                return 'NA';
        }
    },

    x3dateToJSDate: (x3date: string): Date => {
        if (!x3date || x3date === '00/00/0000') return new Date();
        return new Date(Number(x3date.substring(0,4)), Number(x3date.substring(4, 6)) - 1, Number(x3date.substring(6)));
    },

    dateToX3Date: (date: Date): string => {
        return `${date.getFullYear()}${getStartPad(date.getMonth() + 1)}${getStartPad(date.getDate())}`;
    },

    dateMinusMonth: (minusMonth: number): Date => {
        let current = new Date();
        let d = current.getDate();
        let m = current.getMonth() + 1;
        let y = current.getFullYear();

        let mAux = m - minusMonth;

        if (mAux <= 0) {
            m = 12 + mAux; //somar porque já é negativo o mAux
            y -= 1;
        } else {
            m = mAux;
        }

        return new Date(y, m - 1, d);
    },

    getMenuFromDescription: (desc: string, menu: number): any => {
        let menuValues: any = {};
        let startIdx = desc.indexOf(`<MNU NO="${menu}">`);
        let endIdx = desc.indexOf(`</MNU>`, startIdx);
        let menuStr = escapeHTML(desc.substring(startIdx, endIdx));

        let valRgx = /IND="([0-9]+)" C_ENG="([a-zA-Z &]+)"/g;
        for (const m of menuStr.matchAll(valRgx)) {
            menuValues[m[1]] = m[2];
        }

        return menuValues;
    },
};

const escapeHTML = (value: string): string => {
    return value.replace(/&amp;/g, '&');
}

const getMonthDate = (month: string): string => {
    switch (month) {
        case '01':
            return 'ENERO';
        case '02':
            return 'FEBRERO';
        case '03':
            return 'MARZO';
        case '04':
            return 'ABRIL';
        case '05':
            return 'MAYO';
        case '06':
            return 'JUNIO';
        case '07':
            return 'JULIO';
        case '08':
            return 'AGOSTO';
        case '09':
            return 'SEPTIEMBRE';
        case '10':
            return 'OCTUBRE';
        case '11':
            return 'NOVIEMBRE';
        case '12':
            return 'DICIEMBRE';
        default:
            return '-';
    }
}

const getStartPad = (n: number): string => {
    return n.toString().padStart(2, '0');
}