import { useContext } from 'react';
import { IconButton, } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';
import { AuthContext } from '../context/AuthProvider';
import { LOGOUT } from '../context/models/authModel';


const LogOut = () => {

    const { state, dispatch } = useContext(AuthContext);

    return <IconButton color="inherit" onClick={() => dispatch({ type: LOGOUT, payload: state })}>
                <PowerSettingsNew />
            </IconButton>
}

export default LogOut