import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

interface IConfirmRecoverPassword {
    show: boolean,
    title: string | undefined,
    message: string  | undefined,
    setShow: (a: boolean) => void
}

const ConfirmRecoverdPassword: React.FC<IConfirmRecoverPassword> = (props) => {
    return <Dialog
            open={props.show}
            onClose={() => props.setShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className={'classes.confirmationTitle'}>
                { props.title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { props.message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => props.setShow(false)}
                    color="primary"
                    className={'classes.confirmationTitle'}
                    autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
}

  export default ConfirmRecoverdPassword;