import { ExtFile } from "@files-ui/react";

export const reorderAndFilterFiles = (files: ExtFile[]): ExtFile[] => {

    const filesAux: { file: ExtFile, date: number }[]
        = files.map((f: ExtFile) => ({ file: f, date: getYearMonthFromName(f.name ?? '') }));

    const filteredFiles = filesAux.filter(f => isLast24Month(f.date));

    filteredFiles.sort((f1, f2) => f2.date - f1.date);

    return filteredFiles.map(f => f.file);
}

const isLast24Month = (date: number): boolean => {
    const curDate = new Date();
    const curYear = curDate.getFullYear();
    const curMonth = ('0' + (curDate.getMonth() + 1)).slice(-2);

    const today = Number(`${curYear}${curMonth}`);
    const firstDate = Number(`${curYear - 2}${curMonth}`);

    return date >= firstDate && date <= today;
}

const getYearMonthFromName = (filename: string): number => {
    const shortname = filename.replace('.pdf', '');
    const [nif, month, year] = shortname.split('-');
    return Number(`${year}${monthToNumber(month.toLowerCase())}`);
}

const monthToNumber = (month: string): string => {
    switch(month) {
        case 'enero': return '01';
        case 'febrero': return '02';
        case 'marzo': return '03';
        case 'abril': return '04';
        case 'mayo': return '05';
        case 'junio': return '06';
        case 'julio': return '07';
        case 'agosto': return '08';
        case 'septiembre': return '09';
        case 'octubre': return '10';
        case 'noviembre': return '11';
        case 'diciembre': return '12';
        default: return '01';
    }
}