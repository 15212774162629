export const getDateString = (date: Date): string  => {
    return `${date.getFullYear()}${getMonthPadStart(date.getMonth())}${getDayPadStart(date.getDate())}`;
}

export const isWeekend = (date: Date): boolean => {
    let dayOfWeek = date.getDay();
    return (dayOfWeek === 6) || (dayOfWeek  === 0);
}

const getMonthPadStart = (month: number) => (month + 1).toString().padStart(2, '0');
const getDayPadStart = (day: number) => day.toString().padStart(2, '0');

export const getStringDate = (date: string): string => {
    return `${date.substring(6)}/${date.substring(4,6)}/${date.substring(0,4)}`
}