import { useState, createContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IConfirmationMsg, initialState, IAlertConfirm, CONFIRMATION_TYPES } from './models/confirmationMessageModel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    confirmationTitle: {
        color: theme.palette.primary.main
    },
    disagree: {
        color: theme.palette.error.main
    },
    errorTitle: {
        color: theme.palette.error.main
    },
    warningTitle: {
        color: theme.palette.info.main
    }
}));


export const ConfirmMessageContext = createContext<{
    confirmationMsg: IConfirmationMsg;
    setConfirmationMsg: (a: IConfirmationMsg) => void;
}>({
    confirmationMsg: initialState,
    setConfirmationMsg: (a) => { }
});


const AlertConfirm: React.FC<IAlertConfirm> = (props) => (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className={props.agreeButton}>
            { props.title }
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            { props.content }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleDisagree} className={props.disagree}>
                Disagree
            </Button>
            <Button
                onClick={props.handleAgree}
                color="primary"
                className={props.agreeButton}
                autoFocus>
                Agree
            </Button>
        </DialogActions>
    </Dialog>
)

const ConfirmMessageProvider: React.FC<{ children: any}> = ({ children }) => {

    const { classes } = useStyles();

    const [confirmationMsg, setConfirmationMsg] = useState<IConfirmationMsg>(initialState);

    const colorType = (type: CONFIRMATION_TYPES) => {
        switch(type) {
            case CONFIRMATION_TYPES.ERROR: return classes.errorTitle;
            case CONFIRMATION_TYPES.WARNING: return classes.warningTitle;
            case CONFIRMATION_TYPES.CONFIRMATION: return classes.confirmationTitle;
            default: return classes.confirmationTitle;
        }
    }

    const handleClose = () => {
        setConfirmationMsg({
            ...confirmationMsg,
            hasConfirmationMsg: false
        })
    }

    const handleAgree = () => {
        setConfirmationMsg({
            ...confirmationMsg,
            hasConfirmationMsg: false
        })
        confirmationMsg.agree()
    }

    return <ConfirmMessageContext.Provider value={{ confirmationMsg, setConfirmationMsg }}>
                { children }
                <AlertConfirm
                    open={confirmationMsg.hasConfirmationMsg}
                    handleClose={handleClose}
                    title={confirmationMsg.title}
                    content={confirmationMsg.content}
                    handleDisagree={handleClose}
                    handleAgree={handleAgree}
                    disagree={classes.disagree}
                    agreeButton={colorType(confirmationMsg.confirmationType)} />
            </ConfirmMessageContext.Provider>
}

export default ConfirmMessageProvider;