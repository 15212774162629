import { useState, useContext } from 'react';
import { Button, LinearProgress, TextField,InputAdornment, Typography } from '@mui/material';
import { Person, Lock, Email, Check } from '@mui/icons-material';

import { ErrorMessageContext } from '../context/ErrorMessageProvider';
import { LoadingContext } from '../context/LoadingProvider';
import { makeStyles } from 'tss-react/mui';

import { isEmail } from '../helpers/check';
import { ws } from '../ws/call';

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginBottom: theme.spacing(0),
      fontSize: '1.2rem'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '& .MuiFormControl-marginNormal': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        margin: '8px 0'
      }
    },
    '& input': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        padding: '12px 2px'
      }
    },
    '& button': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        margin: '8px 0 4px'
      }
    }
  },
  inputTextFld: {
    backgroundColor: 'white',
    '& input': {
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        padding: '12px 2px'
      }
    }
  },
  primaryIcon: {
    color: theme.palette.primary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
}));

interface ISignUp {
    demoMode: boolean,
    goToSignIn: (a: string, b: string) => void
}

const SignUp: React.FC<ISignUp> = (props) => {
  
    const {classes } = useStyles();

  const { setError } = useContext(ErrorMessageContext);
  const { setLoading } = useContext(LoadingContext);

  const [yourname, setYourname] = useState<string>('');
  const [yournameError, setYournameError] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [companyEmail, setCompanyEmail] = useState<string>('');
  const [companyEmailError, setCompanyEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const handleUsername = (ev: any) => {
    ev.preventDefault();

    let { value } = ev.target;

    setYourname(value);
    if(value) {
        setYournameError('');
        let names = value.trim().split(' ');
        let avatar = `${names[0][0].toUpperCase()}${names.length > 1 ? names[names.length - 1][0].toUpperCase() : ''}`;
        setAvatar(avatar);
    } else {
        setAvatar('');
    }
  }

  const handleCompanyName = (ev: any) => {
    ev.preventDefault();

    let { value } = ev.target;

    setCompanyEmail(value);
    if(value)
        setCompanyEmailError('');
  }

  const handlePassword = (ev: any) => {
    ev.preventDefault();

    let { value } = ev.target;

    setPassword(value);
    if(value)
      setPasswordError('');
  }

  const handleConfirmPassword = (ev: any) => {
    ev.preventDefault();

    let { value } = ev.target;

    setConfirmPassword(value);
    if(value)
        setConfirmPasswordError('');
  }

  const signUp = (ev: any) => {
    ev.preventDefault();

    if(props.demoMode) {
        setError({
            hasError: true,
            title: 'Demo Mode',
            message: 'Can\'t perform this operation in Demo Mode'
        })
    }

    setLoading(true);

    if(!yourname) {
        setYournameError('Missing');
        setLoading(false);
        return;
    }

    if(!companyEmail) {
        setCompanyEmailError('Missing');
        setLoading(false);
        return;
    }

    if(!isEmail(companyEmail)) {
        setCompanyEmailError('Not an email');
        setLoading(false);
        return;
    }

    if(password && password !== confirmPassword) {
        setConfirmPasswordError('Passwords do not match!');
        setLoading(false);
        return;
    }

    ws.createCustomer(yourname, avatar, companyEmail, password)
        .then(ok => {
            props.goToSignIn(`Hi there ${yourname}!`, `Your account is being validated by F5IT. Once it's been approved, you will be notified by email to ${companyEmail}.`);
        })
        .catch(err => {
            setError({
              hasError: true,
              title: 'Sign Up Error',
              message: err.message
            });
        })
        .finally(() => {
            setLoading(false);
        })
}

  return <>
          <Typography variant="h5" color="primary" className={classes.title}>
            Register Your Company
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={ classes.inputTextFld }
              variant="outlined"
              margin="normal"
              fullWidth
              id="yourname"
              label="Your name"
              name="yourname"
              value={yourname}
              autoFocus
              error={yournameError ? true : false}
              helperText={yournameError}
              onChange={handleUsername}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person className={ classes.primaryIcon } />
                  </InputAdornment>
                ),
                endAdornment:(
                    <InputAdornment position="end">
                        { avatar }
                    </InputAdornment>
                )}}
            />
            <TextField
              className={ classes.inputTextFld }
              variant="outlined"
              margin="normal"
              fullWidth
              id="companyEmail"
              label="Your Company Email Address"
              name="companyEmail"
              value={companyEmail}
              autoFocus
              error={companyEmailError ? true : false}
              helperText={companyEmailError}
              onChange={handleCompanyName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email className={ classes.primaryIcon } />
                  </InputAdornment>
                ),
              }}/>
            <TextField
                className={classes.inputTextFld}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                error={passwordError ? true : false}
                helperText={passwordError}
                onChange={handlePassword}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Lock className={ classes.primaryIcon } /> 
                    </InputAdornment>
                )
              }}/>
            <TextField
                className={classes.inputTextFld}
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                error={confirmPasswordError ? true : false}
                helperText={confirmPasswordError}
                onChange={handleConfirmPassword}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Check className={ classes.primaryIcon } /> 
                    </InputAdornment>
                )
              }}/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit }
              onClick={signUp}>
              { 'Register' }
            </Button>
          </form>
        </>
}

export default SignUp;