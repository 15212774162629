import { IUserData } from "../../ws/models/userModels";

export interface IAuthContext {
    isAuthenticated: boolean,
    info: IUserData | null,
    token: string | null
}

export const INITIAL_STATE: IAuthContext = {
    isAuthenticated: false,
    info: null,
    token: null
}

export const LOCAL_STORAGE_KEY = 'PORTALX3USER';
export const DEMO = 'DEMO';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const initialState = (): IAuthContext => {
    const localValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if(localValue !== null) {
        return JSON.parse(localValue) as IAuthContext;
    }

    return INITIAL_STATE;
}

export const userReduce = (state: IAuthContext, action: { type: string, payload: IAuthContext }) => {
    switch (action.type) {
        case LOGIN:
        case DEMO:
            return {
                ...state,
                isAuthenticated: action.payload!.isAuthenticated,
                info: action.payload!.info,
                token: action.payload!.token,
            }
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                info: null,
                token: null,
            }
        default:
            return state;
    }
}