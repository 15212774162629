import { Button, InputAdornment, TextField } from '@mui/material';
import { FilterAlt as FilterAltIcon } from '@mui/icons-material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    typography: {
        alignSelf: 'center',
    },
    filterText: {
        marginTop: '20px',
        marginBottom: '50px',
        marginRight: '10px',
        width: '25ch'
    },
    filterButton: {
        color: theme.palette.text.primary,
		backgroundColor: theme.palette.primary.main,
		float: 'right',
        marginTop: '25px',
		marginBottom: '10px',
    }
}));

interface IInOutFilters {
    filter: (nifFilter: string, nameFilter: string, secondNameFilter: string) => void;
}

const InOutFilters: React.FC<IInOutFilters> = ({ filter }) => {

    const { classes } = useStyles();

    const [nifFilter, setNifFilter] = useState<string>('');
    const [nameFilter, setNameFilter] = useState<string>('');
    const [idEmpleadoFilter, setIdEmpleadoFilter] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);

    const setFilter = (type: string, value: string) => {
        switch(type) {
            case 'nif': setNifFilter(value); break;
            case 'name': setNameFilter(value); break;
            case 'idEmpleado': setIdEmpleadoFilter(value); break;
        }
        setDisabled(false);
    }

    const handleFilter = () => {
        setDisabled(true);
        filter(nifFilter, nameFilter, idEmpleadoFilter);
    }

    return <>
            <TextField
                className={classes.filterText}
                value={nifFilter}
                onChange={(ev: any) => setFilter('nif', ev.target.value!)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">NIF</InputAdornment>,
                }} />
            <TextField
                className={classes.filterText}
                value={nameFilter}
                onChange={(ev: any) => setFilter('name', ev.target.value!)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">Nombre</InputAdornment>,
                }} />
            <TextField
                className={classes.filterText}
                value={idEmpleadoFilter}
                onChange={(ev: any) => setFilter('idEmpleado', ev.target.value!)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">ID</InputAdornment>,
                }} />
            <Button
                className={classes.filterButton}
                variant="contained"
                onClick={handleFilter}
                disabled={disabled}
                startIcon={<FilterAltIcon />}>
                Filter
            </Button>
        </>
}

export default InOutFilters;