export const initialState: IStaticOptions = {
    hasStaticOptions: false,
    title: '',
    content: '',
    actions: []
}

export interface IAlertIStaticOptions {
    staticOptions: IStaticOptions,
    handleClose: () => void
}

export interface IStaticOptions {
    hasStaticOptions: boolean,
    title: string,
    content: string,
    actions: IAction[]
}

export interface IAction {
    name: string,
    action: () => void
}